import React from "react";
import "./styles.css";
import { Button, Divider, Typography } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import referredByIcon from "../../assests/images/referredByIcon.svg";
import referralIcon from "../../assests/images/referralIcon.svg";
import { useNavigate } from "react-router-dom";

// const PatientRecordsReport = ({report, inference}) => {
const PatientRecordsReport = ({ report, inference, isMobile }) => {
    const navigate = useNavigate();
    const date = new Date(report?.created_at ? report?.created_at : null).toDateString();

    const handleHeaderDivClassname = () => {
        if((inference.left === "Mild Diabetic Retinopathy" ||
        inference.left === "No Diabetic Retinopathy"
        )
            && (inference.right === "Mild Diabetic Retinopathy" ||
            inference.right === "No Diabetic Retinopathy"
            )) {
            return `patientRecordsReportHeader headerGreen`;
        }
        else if(inference.left === "Hazy Image (No AI Inference)" || inference.right === "Hazy Image (No AI Inference)"){
            return `patientRecordsReportHeader headerGrey`
        }
        else if(inference.left === "Proliferative Diabetic Retinopathy" ||
        inference.right === "Proliferative Diabetic Retinopathy" ||
        inference.left === "Severe Diabetic Retinopathy" ||
        inference.right === "Severe Diabetic Retinopathy"
        ) {
            return `patientRecordsReportHeader headerRed`;
        }
        else if(inference.left === "Moderate Diabetic Retinopathy" || inference.right === "Moderate Diabetic Retinopathy") {
            return `patientRecordsReportHeader headerOrange`;
        }
    };

    const handleLeftEyeColor = () => {
        if(inference.left=== "Mild Diabetic Retinopathy" ||
        inference.left=== "No Diabetic Retinopathy"
        ) {
            return "leftEyeGreen";
        }
        else if(inference.left=== "Proliferative Diabetic Retinopathy" ||
        inference.left=== "Severe Diabetic Retinopathy"
        ) {
            return "leftEyeRed";
        }
        else if(inference.left=== "Moderate Diabetic Retinopathy") {
            return "leftEyeOrange";
        }
        else {
            return "leftEyeGrey"
        }
    };

    const handleRightEyeColor = () => {
        if(inference.right === "Mild Diabetic Retinopathy" ||
        inference.right === "No Diabetic Retinopathy"
        ) {
            return "rightEyeGreen";
        }
        else if(inference.right === "Proliferative Diabetic Retinopathy" ||
        inference.right === "Severe Diabetic Retinopathy"
        ) {
            return "rightEyeRed";
        }
        else if(inference.right === "Moderate Diabetic Retinopathy") {
            return "rightEyeOrange";
        }
        else {
            return "rightEyeGrey"
        }
    };

    const handleSetReferral = (left, right) => {
        if((left === "No Diabetic Retinopathy" || left === "Mild Diabetic Retinopathy") && (right === "No Diabetic Retinopathy" || right === "Mild Diabetic Retinopathy")) {
            return "Not Required";
        }
        else {
            return "Required";
        }
    };

    const handleViewReport = (id) => {
        navigate(`/patientrecord/${id}`);
    };

    function replaceSubstring(inputString) {
        return inputString.replace(/Diabetic Retinopathy/gi, "DR");
    }

  return (
    <div>
        {/* <div className="patientRecordsReportDiv"> */}
        <div className={`patientRecordsReportDiv ${isMobile ? "mobile" : ""}`}>
            <div className={handleHeaderDivClassname()}>
                <div className="patientRecordsReportHeaderDiv">
                    {
                        isMobile ? 
                        (
                            <>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ marginRight: '4px' }} fontWeight={600}>{report.patient.name}</Typography>
                                    <Divider orientation="vertical" flexItem sx={{ background: "#000000", fontWeight: "600"}}/>
                                    <Typography style={{ marginLeft: '4px' }} fontWeight={600} textTransform="capitalize">{report.patient.age}Y,  {report.patient.sex}</Typography>
                                </div>
                                <Typography fontWeight={400} color="rgba(0, 0, 0, 0.46)">Last Visit Date: {date}</Typography>
                            </>
                            
                         ) : 
                        (
                            <>
                                <Typography fontWeight={600}>{report.patient.name}</Typography>
                                <Divider orientation="vertical" flexItem sx={{ background: "#000000", fontWeight: "600"}}/>
                                <Typography fontWeight={600} textTransform="capitalize">{report.patient.age}Y,  {report.patient.sex}</Typography>
                                <Divider orientation="vertical" flexItem sx={{ background: "#000000", fontWeight: "600"}}/>
                                <Typography fontWeight={400} color="rgba(0, 0, 0, 0.46)">Last Visit Date: {date}</Typography>
                            </>
                        )
                    }
                </div>
                {!isMobile && <div>
                    <Button
                        disableElevation
                        endIcon={<ArrowForwardIcon />}
                        onClick={() => handleViewReport(report.uuid)}
                        sx={{borderRadius: "3px", border: "1.5px solid var(--white, #FFF)", background: "#FFF", color: "#011F4B"}}
                    >
                        View Report
                    </Button>
                </div>}
            </div>
            <div className="patientRecordsReportInfo">
                <div className="reportDiv">
                    <div className="patientRecordsReportInfoSub">
                        <Typography color="#949494" fontWeight={500} display="flex" alignItems="center" gap="6px">
                            <VisibilityOutlinedIcon />
                            DR in Right Eye:
                        </Typography>
                        <Typography fontWeight={600} className={handleRightEyeColor()}>
                            {isMobile? replaceSubstring(inference.right): inference.right}
                        </Typography>
                    </div>
                    {!isMobile && <Divider orientation="vertical" variant="middle" flexItem/>}
                    <div className="patientRecordsReportInfoSub" style={{paddingLeft: "8px"}}>
                        <Typography color="#949494" fontWeight={500} display="flex" alignItems="center" gap="6px">
                            <VisibilityOutlinedIcon />
                            DR in Left Eye:
                        </Typography>
                        <Typography fontWeight={600} className={handleLeftEyeColor()}>
                            {isMobile? replaceSubstring(inference.left): inference.left}
                        </Typography>
                    </div>
                </div>
                
                {/* <Divider orientation="vertical" variant="middle" flexItem/>
                <div className="patientRecordsReportInfoSub">
                    <Typography color="#949494" fontWeight={500} display="flex" alignItems="center" gap="6px">
                        <CalendarMonthOutlinedIcon />
                        No. of Visits:
                    </Typography>
                    <Typography fontWeight={600}>
                        {report.visitsnumber}
                        Previous Visits
                    </Typography>
                </div> */}
                <Divider orientation="vertical" variant="middle" flexItem/>
                <div className="reportDiv">
                    <div className="patientRecordsReportInfoSub">
                        <Typography color="#949494" fontWeight={500} display="flex" alignItems="center" gap="6px">
                            <img src={referredByIcon} alt="" style={{width: "20px", height: "20px"}}/>
                            Referred by:
                        </Typography>
                        <Typography fontWeight={600} textTransform="capitalize">
                            {report?.doctor?.name}
                        </Typography>
                    </div>
                    {!isMobile && <Divider orientation="vertical" variant="middle" flexItem/>}
                    <div className="patientRecordsReportInfoSub" style={{paddingLeft: "8px"}}>
                        <Typography color="#949494" fontWeight={500} display="flex" alignItems="center" gap="6px">
                        <img src={referralIcon} alt="" style={{width: "20px", height: "20px"}}/>
                            Referral:
                        </Typography>
                        <Typography fontWeight={600}>
                            {handleSetReferral(inference.left, inference.right)}
                        </Typography>
                    </div>
                </div>
                
                {isMobile && <div className="mobileViewReport">
                    <Button
                        disableElevation
                        endIcon={<ArrowForwardIcon />}
                        onClick={() => handleViewReport(report.uuid)}
                        sx={{borderRadius: "3px", border: "1.5px solid var(--white, #FFF)", background: "#FFF", color: "#011F4B"}}
                    >
                        View Report
                    </Button>
                </div>}
            </div>
        </div>
    </div>
  ) 
}

export default PatientRecordsReport;