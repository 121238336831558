// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerBar {
    height: 6vh;
    background: #BDD7F233;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.activeLinkText {
    color: #0D1E83;
    font-size: 18px;
    font-weight: bold !important;
    text-decoration: none;
}

.navLinkText1 {
    color: #7D797B;
    font-size: 18px;
    font-weight: bold !important;
    text-decoration: none;
}
.navLinkText1:hover {
    color: #0D1E83;
}

.navLinkText2 {
    color: var(--Grey-Headings, #949494);
    font-weight: bold;
    font-size: 18px;
    text-decoration: none;
}

.headerBarAccountDiv:hover .navLinkText2 {
    cursor: pointer;
    color: #0D1E83;
}`, "",{"version":3,"sources":["webpack://./src/components/header/styles.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,qBAAqB;IACrB,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,4BAA4B;IAC5B,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,4BAA4B;IAC5B,qBAAqB;AACzB;AACA;IACI,cAAc;AAClB;;AAEA;IACI,oCAAoC;IACpC,iBAAiB;IACjB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".headerBar {\n    height: 6vh;\n    background: #BDD7F233;\n    padding: 0 5%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.activeLinkText {\n    color: #0D1E83;\n    font-size: 18px;\n    font-weight: bold !important;\n    text-decoration: none;\n}\n\n.navLinkText1 {\n    color: #7D797B;\n    font-size: 18px;\n    font-weight: bold !important;\n    text-decoration: none;\n}\n.navLinkText1:hover {\n    color: #0D1E83;\n}\n\n.navLinkText2 {\n    color: var(--Grey-Headings, #949494);\n    font-weight: bold;\n    font-size: 18px;\n    text-decoration: none;\n}\n\n.headerBarAccountDiv:hover .navLinkText2 {\n    cursor: pointer;\n    color: #0D1E83;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
