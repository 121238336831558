import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const HealthDetailsComponent = ({ health_info }) => {
  const HealthInfoHeaders = [
    { header: "Prediagnosed Diabetes:", value: health_info?.preDiagnosedDiabetes === "" ? "N/A" : health_info?.preDiagnosedDiabetes },
    { header: "Duration of Diabetes:", value: health_info?.durationDiabetesDetection === "" ? "N/A" : `${health_info?.durationDiabetesDetection} Years` },
    { header: "Diabetes Type:", value: health_info?.diabetesType === "" ? "N/A" : health_info?.diabetesType },
    { header: "Hb A1 C Level:", value: health_info?.HbA1CLevel === "" ? "N/A" : `${health_info?.HbA1CLevel} mg/dL` },
    { header: "Systolic Blood Pressure:", value: health_info?.systolicBloodPressure === undefined || health_info?.systolicBloodPressure === "" ? "N/A" : `${health_info?.systolicBloodPressure} mmHg` },
    { header: "Diastolic Blood Pressure:", value: health_info?.diastolicBloodPressure === undefined || health_info?.diastolicBloodPressure === "" ? "N/A" : `${health_info?.diastolicBloodPressure} mmHg` },
    { header: "Visual Acuity:", value: `Right: ${health_info?.visualAcuity?.right === "" ? "N/A" : health_info?.visualAcuity?.right}, Left: ${health_info?.visualAcuity?.left === "" ? "N/A" : health_info?.visualAcuity?.left}` },
    { header: "Other complications:", value: health_info?.otherComplications.length > 0 ? health_info?.otherComplications.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(", ") : "N/A" },
    { header: "Family History of Diabetes:", value: health_info?.familyHistory.length > 0 ? health_info?.familyHistory.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(", ") : "N/A" },
    { header: "Notes:", value: `${health_info?.notes === "" ? "N/A" : health_info?.notes}` },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Grid container spacing={2} columns={{ xs: 6, md: 9 }}>
        {HealthInfoHeaders.map((item, index) => (
          <Grid item xs={3} md={3} key={index}>
            <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "#110202" }}>
              {item.header}
            </Typography>
            <Typography textTransform={item.header === "Hb A1 C Level:" || item.header === "Systolic Blood Pressure:" || item.header === "Diastolic Blood Pressure:" ? "none" : "capitalize"}>
              {item.value}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HealthDetailsComponent;
