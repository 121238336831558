import React, { useState } from "react";
import { Container, Typography } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ProblemIssues from "./ProblemIssues";

const ImageBad = ({eye}) => {
    const [problem, setProblem] = useState(false);
    const handleProblemClose = () => {
        setProblem(false);
    }
  return (
    <Container sx={{display: "flex", alignItems: "center", background: "rgba(253, 226, 226, 0.45)", borderRadius: "4px", border: "1px solid var(--box-stroke, rgba(0, 0, 0, 0.05))", padding: "8px", gap: "8px"}}>
        <div style={{background: "#FFE8EB", borderRadius: "50%", display: "flex", justifyContent: "center", alignContent: "center"}}>
            <CloseRoundedIcon sx={{fill: "#FD284A"}} fontSize="small"/>
        </div>
        <Typography fontSize="12px" fontWeight={400}>
            Quality Issues Detected! Re-take.
            <div onClick={() => setProblem(true)}>
                <Typography
                    fontSize="12px" fontWeight={400} fontStyle="italic" color="#0D1E83"
                    sx={{textDecorationLine: "underline", cursor: "pointer"}}
                >
                    See why?
                </Typography>
            </div>
        </Typography>
        {problem && <ProblemIssues open={problem} handleProblemClose={handleProblemClose} eye={eye}/>}
    </Container>
  )
}

export default ImageBad