import React, { useEffect, useState } from "react";
import './App.css';
import Login from './components/login/login';
import Navbar from './components/navbar/navbar';
import Profile from './components/profile/profile';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './components/dashboard/dashboard';
import PatientRecords from './components/patientRecords/patientRecords';
import PatientScreeningWrapper from './components/patientScreeningWrapper/patientScreeningWrapper';
import axios from 'axios';
import config from './config';
import ReportsComponent from "./components/reportsComponent/reportsComponent";

const ProtectedRoute = ({
  element: Element,
  userLoggedIn,
  setUserLoggedIn,
  userData
}) => {
  if (userLoggedIn) {
    return <Element userData={userData}/>;
  } else {
    return (
      <Login userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} />
    );
  }
};

function App() {
  const userData = JSON.parse(localStorage.getItem("user_profile"));
  const token = localStorage.getItem("optha-token");
  const header = {"Authorization": "Token "+token};
  const patient = localStorage.getItem('patient_id');
  const idexists = !!patient;
  let isNotToken = token === "" || token === null;
  const [userLoggedIn, setUserLoggedIn] = useState(!isNotToken);
  useEffect(() => {
    if(idexists) {
      axios
      .get(`${config.API_BASE_URL}/api/auth/users/?id=${patient}`, {headers: header})
      .then((res) => {
        alert(`Form for Patient: ${res.data[0].name}`);
      })
      .catch((error) => {
        alert("No id exits", error);
      })
    }
  }, [idexists]);
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path='/dashboard' element={
          <ProtectedRoute element={Dashboard} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} />
        } />
        <Route path='/account' element={
          <ProtectedRoute element={Profile} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} userData={userData} />
        }/>
        <Route path='/patientscreen' element={
          <ProtectedRoute element={PatientScreeningWrapper} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} userData={userData} />
        } />
        <Route path='/patientrecord' element={
          <ProtectedRoute element={PatientRecords} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} />
        } />
        <Route path='/patientrecord/:patientrecordId' element={
          <ProtectedRoute element={ReportsComponent} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} />
        } />
        <Route path='/' element={
          <ProtectedRoute element={Dashboard} userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} />
        } />
      </Routes>
    </div>
  );
}

export default App;
