import React, { useEffect, useState } from "react";
import { Container, Grid, MenuItem, Select, TextField, Typography, useTheme, useMediaQuery } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import axios from "axios";
import config from "../../config";

const genderArray = ["male", "female", "Gender Divergent", "Prefer Not to Say"];
const NewPatientForm = ({formik, findPatient = false}) => {
    const [docMap, setDocMap] = useState([]);
    useEffect(() => {
        const header = {"Authorization": "Token "+localStorage.getItem("optha-token")};
        axios
        .get(`${config.API_BASE_URL}/api/auth/users/?role=doctor`, {headers: header})
        .then((res) => {
            const mappedArray = res.data.map((item) => ({
                docId: item.id,
                docName: item.name,
            }));
            setDocMap(mappedArray);
        })
        .catch((error) => console.log(error));
    }, []);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDocChange = (event) => {
        const selectedDocName = event.target.value;
        const selectedDoc = docMap.find((doc) => doc.docName === selectedDocName);
        if (selectedDoc) {
            formik.setFieldValue("pat_info.referredBy.docName", selectedDocName);
            formik.setFieldValue("pat_info.referredBy.docId", selectedDoc.docId);
        }
    };


  return (
    <div>
        <Container sx={{padding: !isMobile && "0px 24px"}}>
            <Grid>
            {!findPatient && <Grid container spacing={1} paddingBottom={1}>
                <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
                    <Typography width={isMobile ? "100vw" : "13vw"}>Patient Name*</Typography>
                    <TextField
                        required
                        placeholder="Eg. Rohit Sharma"
                        variant="outlined"
                        id="pat_info.name"
                        name="pat_info.name"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.pat_info.name}
                        sx={{
                            border: "1px solid #0000000D",
                            borderRadius: "4px",
                            width: isMobile ? "100%" : "25vw"
                        }}
                        error={formik.touched.pat_info?.name && formik.errors.pat_info?.name}
                    />
                </Grid>
                {
                    formik.touched.pat_info?.name && formik.errors.pat_info?.name ?
                    (<Typography color="red" >{formik.errors.pat_info.name}</Typography>) : null
                }

                {isMobile ? (
                <Grid container alignItems="center" sx={{marginLeft: 1, marginBottom:1}}>
                    <Grid item xs={6}>
                    <Typography width="40vw">Gender*</Typography>
                    <Select
                        renderValue={(selected) => selected}
                        id="pat_info.gender"
                        name="pat_info.gender"
                        onChange={formik.handleChange}
                        value={formik.values.pat_info.gender ? formik.values.pat_info.gender : `Select the Gender`}
                        sx={{
                        width: "90%",
                        textTransform: "capitalize",
                        fontWeight: formik.values.pat_info.gender ? "" : "lighter",
                        fontStyle: formik.values.pat_info.gender ? "normal" : "italic",
                        borderRadius: "4px"
                        }}
                    >
                        <MenuItem value="" disabled>
                        <em>Select the Gender</em>
                        </MenuItem>
                        {genderArray.map((gender, index) => (
                        <MenuItem key={index} value={gender} sx={{ background: "#F9FCFF", color: formik.values.pat_info.gender === gender ? "#0D1E83" : "", fontWeight: formik.values.pat_info.gender === gender ? "bold" : "" }}>
                            <Typography textTransform="capitalize">{gender}</Typography>
                            {formik.values.pat_info.gender === gender ? <DoneIcon/> : null}
                        </MenuItem>
                        ))}
                    </Select>
                    {formik.touched.pat_info?.gender && formik.errors.pat_info?.gender && (
                        <Typography color="error">{formik.errors.pat_info.gender}</Typography>
                    )}
                    </Grid>
                    <Grid item xs={6}>
                    <Typography width="40vw">Age*</Typography>
                    <TextField
                        required
                        type="number"
                        placeholder="XX"
                        variant="outlined"
                        InputProps={{ endAdornment: 'Years'}}
                        id="pat_info.age"
                        name="pat_info.age"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.pat_info.age}
                        sx={{
                        width: "90%",
                        border: "1px solid #0000000D",
                        borderRadius: "4px"
                        }}
                        error={formik.touched.pat_info?.age && Boolean(formik.errors.pat_info?.age)}
                    />
                    {formik.touched.pat_info?.age && formik.errors.pat_info?.age && (
                        <Typography color="error">{formik.errors.pat_info.age}</Typography>
                    )}
                    </Grid>
                </Grid>
                ) : (
                <>
                    <Grid item container alignItems="center">
                        <Typography width="13vw">Gender*</Typography>
                        {/* <RadioGroup row id="pat_info.gender" name="pat_info.gender" onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.pat_info.gender} sx={{marginLeft: "10px"}}>
                            <FormControlLabel value="male" control={<Radio />} label="Male" sx={{border: "1px solid rgba( 0, 0, 0, 0.05)", borderRadius: "8px", paddingRight: "10px", boxShadow: "1px 1px 10px 0 #4040400D"}}/>
                            <FormControlLabel value="female" control={<Radio />} label="Female" sx={{border: "1px solid rgba( 0, 0, 0, 0.05)", borderRadius: "8px", paddingRight: "10px", boxShadow: "1px 1px 10px 0 #4040400D"}}/>
                            <FormControlLabel value="other" control={<Radio />} label="Other" sx={{border: "1px solid rgba( 0, 0, 0, 0.05)", borderRadius: "8px", paddingRight: "10px", boxShadow: "1px 1px 10px 0 #4040400D"}}/>
                        </RadioGroup> */}
                        <Select
                            renderValue={(selected) => {
                                return selected;
                            }}
                            id="pat_info.gender"
                            name="pat_info.gender"
                            onChange={formik.handleChange}
                            value={formik.values.pat_info.gender ? formik.values.pat_info.gender : `Select the Gender`}
                            sx={{width: "25vw", textTransform: "capitalize", fontWeight: formik.values.pat_info.gender ? "" : "lighter", fontStyle: formik.values.pat_info.gender ? "normal" : "italic", borderRadius: "4px"}}
                        >
                            <MenuItem value="" disabled>
                                <em>Select the Gender</em>
                            </MenuItem>
                            {genderArray.map((gender, index) => (
                                <MenuItem key={index} value={gender} sx={{display: "flex",justifyContent: "space-between", background: "#F9FCFF", color: formik.values.pat_info.gender === gender ? "#0D1E83" : "", fontWeight: formik.values.pat_info.gender === gender ? "bold" : ""}}>
                                    <Typography textTransform="capitalize">{gender}</Typography>
                                    {formik.values.pat_info.gender === gender ? <DoneIcon/> : null}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    {
                        formik.touched.pat_info?.gender && formik.errors.pat_info?.gender ?
                        (<Typography color="red" >{formik.errors.pat_info.gender}</Typography>) : null
                    }
                    <Grid item container alignItems="center">
                        <Typography width="13vw">Age*</Typography>
                        <TextField
                            required
                            type="number"
                            placeholder="XX"
                            variant="outlined"
                            InputProps={{ endAdornment: 'Years'}}
                            id="pat_info.age"
                            name="pat_info.age"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.pat_info.age}
                            sx={{
                                border: "1px solid #0000000D",
                                borderRadius: "4px",
                                // width: "25vw"
                            }}
                            error={formik.touched.pat_info?.age && formik.errors.pat_info?.age}
                        />
                    </Grid>
                    {
                        formik.touched.pat_info?.age && formik.errors.pat_info?.age ?
                        (<Typography color="red" >{formik.errors.pat_info.age}</Typography>) : null
                    }
                </>
                )}

                <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
                    <Typography width={isMobile ? "100vw" : "13vw"}>Other ID*</Typography>
                    <TextField
                        required
                        placeholder="Eg. 1234ABCD33DD"
                        variant="outlined"
                        id="pat_info.otherId"
                        name="pat_info.otherId"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.pat_info.otherId}
                        sx={{
                            border: "1px solid #0000000D",
                            borderRadius: "4px",
                            width: isMobile ? "100%" : "25vw"
                        }}
                        error={formik.touched.pat_info?.otherId && formik.errors.pat_info?.otherId}
                    />
                </Grid>
                {
                    formik.touched.pat_info?.otherId && formik.errors.pat_info?.otherId ?
                    (<Typography color="red">{formik.errors.pat_info.otherId}</Typography>) : null
                }
                <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
                    <Typography width={isMobile ? "100vw" : "13vw"}>ABHA ID</Typography>
                    <TextField
                        required
                        placeholder="Eg. 1234ABCD33DD"
                        variant="outlined"
                        id="pat_info.abhaId"
                        name="pat_info.abhaId"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.pat_info.abhaId}
                        sx={{
                            border: "1px solid #0000000D",
                            borderRadius: "4px",
                            width: isMobile ? "100%" : "25vw"
                        }}
                        error={formik.touched.pat_info?.abhaId && formik.errors.pat_info?.abhaId}
                    />
                </Grid>
                {
                    formik.touched.pat_info?.abhaId && formik.errors.pat_info?.abhaId ?
                    (<Typography color="red">{formik.errors.pat_info.abhaId}</Typography>) : null
                }
                <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
                    <Typography width={isMobile ? "100vw" : "13vw"}>Contact Number*</Typography>
                    <TextField
                        required
                        type="number"
                        placeholder="XXXXX XXXXX"
                        variant="outlined"
                        InputProps={{startAdornment: '+91'}}
                        id="pat_info.number"
                        name="pat_info.number"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.pat_info.number}
                        sx={{
                            border: "1px solid #0000000D",
                            borderRadius: "4px",
                            width: isMobile ? "100%" : "25vw"
                        }}
                        error={formik.touched.pat_info?.number && formik.errors.pat_info?.number}
                    />
                </Grid>
                {
                    formik.touched.pat_info?.number && formik.errors.pat_info?.number ?
                    (<Typography color="red">{formik.errors.pat_info.number}</Typography>) : null
                }
            </Grid>}
                <Grid item container direction={isMobile ? "column" : "row"} alignItems={isMobile ? "left" : "center"} sx={isMobile && {marginBottom:1}}>
                    <Typography width={isMobile ? "100vw" : "13vw"}>Referred by*</Typography>
                    <Select
                        renderValue={(selected) => {
                            return selected;
                        }}
                        id="pat_info.referredBy.docName"
                        name="pat_info.referredBy.docName"
                        onChange={handleDocChange}
                        value={formik.values.pat_info.referredBy.docName ? formik.values.pat_info.referredBy.docName : `Select the Doctor`}
                        sx={{width: isMobile ? "100%" : "25vw", textTransform: "capitalize", fontWeight: formik.values.pat_info.referredBy.docName ? "" : "lighter", fontStyle: formik.values.pat_info.referredBy.docName ? "normal" : "italic"}}
                    >
                        <MenuItem value="" disabled>
                            <em>Select the Doctor</em>
                        </MenuItem>
                        {docMap.map((docItem, index) => (
                            <MenuItem key={index} value={docItem.docName} sx={{display: "flex",justifyContent: "space-between", background: "#F9FCFF", color: formik.values.pat_info.referredBy.docName === docItem.docName ? "#0D1E83" : "", fontWeight: formik.values.pat_info.referredBy.docName === docItem.docName ? "bold" : ""}}>
                                <Typography textTransform="capitalize">{docItem.docName}</Typography>
                                {formik.values.pat_info.referredBy.docName === docItem.docName ? <DoneIcon/> : null}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>
        </Container>
    </div>
  )
}

export default NewPatientForm