// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navHead {
    height: 11.5vh;
    width: 100vw;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navHead_icn {
    height: 11.5vh;
    width: 100%;
    padding: 0 5%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .gov_img {
    height: 7vh;
    width: 16vw;
    object-fit: contain;
  }
  
  .dia_img {
    height: 7vh;
    width: 16vw;
    object-fit: contain;
  }
  

  
  @media (max-width: 900px) {
    .gov_img {
      display: none;
    }
  
    .dia_img {
      width: 30vw;
      height: auto;
      object-fit: contain;
    }
  
    .navHead_icn {
      justify-content: flex-start;
      padding-left: 5%;
    }
  
    .navMenu {
      margin-right: 5%;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/navbar/styles.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;IACZ,+CAA+C;IAC/C,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,WAAW;IACX,aAAa;IACb,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,WAAW;IACX,mBAAmB;EACrB;;;;EAIA;IACE;MACE,aAAa;IACf;;IAEA;MACE,WAAW;MACX,YAAY;MACZ,mBAAmB;IACrB;;IAEA;MACE,2BAA2B;MAC3B,gBAAgB;IAClB;;IAEA;MACE,gBAAgB;IAClB;EACF","sourcesContent":[".navHead {\n    height: 11.5vh;\n    width: 100vw;\n    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  \n  .navHead_icn {\n    height: 11.5vh;\n    width: 100%;\n    padding: 0 5%;\n    background-color: #FFFFFF;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n  }\n  \n  .gov_img {\n    height: 7vh;\n    width: 16vw;\n    object-fit: contain;\n  }\n  \n  .dia_img {\n    height: 7vh;\n    width: 16vw;\n    object-fit: contain;\n  }\n  \n\n  \n  @media (max-width: 900px) {\n    .gov_img {\n      display: none;\n    }\n  \n    .dia_img {\n      width: 30vw;\n      height: auto;\n      object-fit: contain;\n    }\n  \n    .navHead_icn {\n      justify-content: flex-start;\n      padding-left: 5%;\n    }\n  \n    .navMenu {\n      margin-right: 5%;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
