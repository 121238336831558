import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const SubmitReportDialog = ({open, handlePreviewClose, handleSubmitReport}) => {
  return (
    <Dialog open={open} fullWidth={true} maxWidth="xs" height="md">
        <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontWeight={500} fontSize="20px" color="#2D363D">
                Submit Report
            </Typography>
            <Button endIcon={<CloseIcon />} onClick={handlePreviewClose} sx={{color: "#0D1E83"}}>Close</Button>
        </DialogTitle>
        <DialogContent sx={{padding: "2.5% 4%"}}>
            <Typography fontSize="16px" fontWeight={400}>
                Are you sure you want to submit patient report? Once submitted the report will be send to the Ophthalmologist and couldn't be edited
            </Typography>
        </DialogContent>
        <DialogActions>
            <Box width="100%" display= "flex" justifyContent="space-evenly">
                <Button
                    sx={{
                        border: "2px solid var(--Text-Red, #9F0101)",
                        color: "#9F0101",
                        borderRadius: "8px",
                    }}
                    onClick={handlePreviewClose}
                >
                    Don’t Submit Report
                </Button>
                <Button
                    sx={{
                        border: "1px solid var(--White, #FFF)",
                        background: "var(--Gradient, linear-gradient(270deg, #051572 7.32%, #0820B4 92.68%))",
                        borderRadius: "8px",
                        color: "#FFFFFF",
                    }}
                    onClick={() => {handleSubmitReport(); handlePreviewClose();}}
                >
                    Submit Report
                </Button>
            </Box>
        </DialogActions>
    </Dialog>
  )
}

export default SubmitReportDialog