import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery, 
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import AddIcon from "@mui/icons-material/Add";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import PdfTemplate from "../pdfTemplate/pdfTemplate";
import axios from "axios";
import config from "../../config";
import { nextSteps, handleSetDR } from "../../utlis";

const PrintDialogBox = ({ open, setIsDialog, addPatient }) => {
  const [downloadPdfFlag, setDownloadPdfFlag] = useState(false);
  const header = {
    Authorization: "Token " + localStorage.getItem("optha-token"),
  };
  const report_id = localStorage.getItem("report_id");
  const [fileName, setFileName] = useState("");
  const [report_data, setReportData] = useState({});
  const [images, setImages] = useState([]);
  let doc_name = localStorage.getItem("doc_name");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getReportData = async () => {
    try {
      const response = await axios.get(
        `${config.API_BASE_URL}/api/reports/getreport/?uuid=${report_id}`,
        { headers: header }
      );
      console.log(doc_name, response.data.results[0].doctor.name);
      setReportData(response.data.results[0]);
      imagetoBase64(response.data.results[0].files.left, response.data.results[0].files.right);
      if (response.data.results[0].patient.name) {
        setFileName(response.data.results[0].patient.name.replace(" ", "_"));
      }
      setDownloadPdfFlag(true);
    } catch (error) {
      console.log(error);
    }
  };
function imagetoBase64(imageURL1, imageURL2) {
// Create an array of promises for fetching the images
const fetchPromises = [
  fetch(imageURL1),
  fetch(imageURL2),
];
// Use Promise.all to wait for both image fetch requests to complete
Promise.all(fetchPromises)
  .then(responses => {
    // Extract the Blob data for each image
    const imageBlobs = responses.map(response => response.blob());
    // Process the image Blobs as needed
    Promise.all(imageBlobs)
      .then(blobs => {
        // blobs[0] contains the Blob for the first image
        // blobs[1] contains the Blob for the second image
        // You can convert the Blobs to URLs or Base64 strings here
        const imageURLs = blobs.map(blob => URL.createObjectURL(blob));
        // Use imageURLs as needed (e.g., display the images)
        console.log('Image URLs:', imageURLs);
        setImages(imageURLs);
      })
      .catch(error => {
        console.error('Error processing image Blobs:', error);
      });
  })
  .catch(error => {
    console.error('Error fetching images:', error);
  });
};
const leftInference = handleSetDR(
  report_data?.ml_model?.classification?.left,
  report_data.ml_model?.gradibility?.left
);
const rightInference = handleSetDR(
  report_data?.ml_model?.classification?.right,
  report_data?.ml_model?.gradibility?.right
);
const { date, message } = nextSteps(leftInference, rightInference);

const obj = {
    heading: "Diabetic Retinopathy Screening Report",
    patientDetails: [
      { label: "Patient Name:", value: `${report_data.patient?.name[0].toUpperCase()}${report_data.patient?.name.slice(1)}` },
      { label: "Age:", value: `${report_data.patient?.age} Year` },
      { label: "Gender:", value: `${report_data.patient?.sex[0].toUpperCase()}${report_data.patient?.sex.slice(1)}` },
      {
        label: "Patient ID:",
        value:
          report_data.patient?.other_id !== null
            ? report_data.patient?.other_id
            : report_data.patient?.abha_id,
      },
      { label: "Phone Number:", value: `+91 ${report_data.patient?.phone}` },
      { label: "Referred by:", value: `Dr. ${report_data.doctor?.name}` },
    ],
    url: report_data?.uuid,
    images: {
      img1: images[0],
      img2: images[1],
    },
    healthInfo: [
      {
        label: "Pre-diagnosed Diabetes:",
        value: report_data.health_info?.preDiagnosedDiabetes !== ""
        ? `${report_data.health_info?.preDiagnosedDiabetes[0].toUpperCase()}${report_data.health_info?.preDiagnosedDiabetes.slice(
            1
          )}`
        : "N/A",
      },
      {
        label: "Duration of Diabetes Detection:",
        // value: processInfo(report_data.health_info, "durationDiabetesDetection", "Year"),
        value: report_data.health_info?.durationDiabetesDetection !== ""
        ? `${report_data.health_info?.durationDiabetesDetection} Years`
        : "N/A"
      },
      {
        label: "Diabetes Type:",
        // value: processInfo(report_data.health_info, "diabetesType", "")
        value: report_data.health_info?.diabetesType !== ""
        ? `${report_data.health_info?.diabetesType[0].toUpperCase()}${report_data.health_info?.diabetesType.slice(
            1
          )}`
        : "N/A"
      },
      {
        label: "Hb A1 C Level:",
        // value: processInfo(report_data.health_info, "HbA1CLevel", "mg/dL")
        value: report_data.health_info?.HbA1CLevel !== ""
        ? `${report_data.health_info?.HbA1CLevel} mg/dL`
        : "N/A"
      },
      {
        label: "Systolic Blood Pressure:",
        // value: processInfo(report_data.health_info, "bloodPressure", "mm/Hg"),
        value:
          report_data.health_info?.systolicBloodPressure !== "" && report_data.health_info?.systolicBloodPressure !== undefined
            ? `${report_data.health_info?.systolicBloodPressure} mm/Hg`
            : "N/A",
      },
      {
        label: "Diastolic Blood Pressure:",
        // value: processInfo(report_data.health_info, "bloodPressure", "mm/Hg"),
        value:
          report_data.health_info?.diastolicBloodPressure !== "" && report_data.health_info?.diastolicBloodPressure !== undefined
            ? `${report_data.health_info?.diastolicBloodPressure} mm/Hg`
            : "N/A",
      },
      {
        label: "Visual Acuity:",
        value: `Right: ${
          report_data.health_info?.visualAcuity?.right !== ""
            ? report_data.health_info?.visualAcuity?.right
            : "N/A"
        }, Left: ${
          report_data.health_info?.visualAcuity?.left !== ""
            ? report_data.health_info?.visualAcuity?.left
            : "N/A"
        }`,
      },
      {
        label: "Other complications:",
        value: report_data.health_info?.otherComplications ?
          report_data.health_info?.otherComplications.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(', ') :
          "N/A",
      },
      {
        label: "Family History of Diabetes:",
        value: report_data.health_info?.familyHistory ?
        report_data.health_info?.familyHistory.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(', ') :
        "N/A",
      },
    ],
    notes: report_data.health_info?.notes !== null ? report_data.health_info?.notes : "N/A",
    nextSteps:
      `${message} and suggest them to monitor their sugar level.`,
    hospitalVisit:
      `If you’re looking for a medical advise, visit Dr. ${report_data.doctor?.name} at ${report_data.doctor?.metadata?.hospital !== "" ? report_data.doctor?.metadata?.hospital : "AIIMS DELHI"} Hospital.`,
    disclaimer:
      "The report does not replace professional medical advice, diagnosis or treatment",
  };
  return (
    <>
        {downloadPdfFlag && (
            <PdfTemplate
                downloadFlag={downloadPdfFlag}
                fileName={fileName}
                heading={obj.heading}
                patientDetails={obj.patientDetails}
                url={obj.url}
                images={obj.images}
                healthInfo={obj.healthInfo}
                notes={obj.notes}
                nextSteps={obj.nextSteps}
                hospitalVisit={obj.hospitalVisit}
                disclaimer={obj.disclaimer}
                setDownloadFlag={setDownloadPdfFlag}
            />
        )}
        <div>
            <Dialog
                open={open}
                width="lg"
                maxWidth="lg"
                height="md"
                borderRadius="16px"
                sx={{
                    boxShadow:
                        "0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)",
                }}
            >
                <DialogTitle display="flex" justifyContent="end" alignItems="center">
                    <Button
                        endIcon={<CloseIcon />}
                        onClick={() => {
                            setIsDialog(false);
                            localStorage.removeItem("report_id");
                            localStorage.removeItem("doc_name");
                        }}
                        sx={{
                            color: "#0D1E83",
                            "&:hover": { backgroundColor: "var(--Blue, #FFFFFF)" },
                        }}
                    >
                        {!isMobile && "Close"}
                    </Button>
                </DialogTitle>
                <DialogContent
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        paddingX: "44px",
                    }}
                    className="dialogContent"
                >
                    <TaskAltIcon
                        sx={{ color: "#00850D", height: "60px", width: "60px" }}
                    />
                    <Typography
                        display="flex"
                        color="#2D363D"
                        fontSize="24px"
                        fontWeight={500}
                        gap={1}
                        alignItems="center"
                    >
                        Report Submitted
                        <Typography color="#00850D" fontWeight={600}>
                            Successfully
                        </Typography>
                    </Typography>
                    <Typography
                        color="#404040"
                        fontWeight={500}
                        fontSize="17px"
                        textAlign="center"
                    >
                        {`The report has been successfully sent to Dr. ${doc_name},
                        Ophthalmologist at AIIMS Delhi`}
                    </Typography>
                </DialogContent>
                <DialogActions
                    width="100%"
                    display="flex"
                    sx={{ justifyContent: "space-evenly", paddingX: "44px" }}
                    className="dialogActions"
                >
                    <Button
                        variant="contained"
                        sx={{
                            borderRadius: "8px",
                            border: "2px solid var(--Blue, #0D1E83)",
                            background: "var(--Blue, #0D1E83)",
                            color: "#FFFFFF",
                            "&:hover": { backgroundColor: "var(--Blue, #0D1E83)" },
                        }}
                        onClick={addPatient}
                        startIcon={<AddIcon />}
                    >
                        Add New Patient
                    </Button>
                    <Button
                        sx={{
                            borderRadius: "8px",
                            border: "2px solid var(--Blue, #0D1E83)",
                            color: "#0D1E83",
                            "&:hover": { backgroundColor: "var(--Blue, #FFFFFF)" },
                        }}
                        onClick={() => getReportData()}
                        startIcon={<LocalPrintshopOutlinedIcon />}
                    >
                        Print Report
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    </>
);

};

export default PrintDialogBox;
