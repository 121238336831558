import React from 'react';
import "./styles.css";
import diabeticImg from "../../assests/images/Diabetic.jpg";
import indianGov from "../../assests/images/IndianGov.jpg";
import Menu from "../menuComponent/menu";

const Navbar = () => {
  return (
    <div className='navHead'>
      <div className='navHead_icn'>
        <img
          className='dia_img'
          src={diabeticImg}
          alt='Diabetic Retinopathy'
        />
        <img
          className='gov_img'
          src={indianGov}
          alt='Indian Government'
        />
      </div>
      <div className='navMenu'>
        <Menu />
      </div>
    </div>
  )
}

export default Navbar;
