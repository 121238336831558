import React, { useState } from "react";
import { Typography, Drawer, List, ListItem, ListItemText, ListItemIcon, IconButton, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '../../assests/images/dashboard.svg';
import DashboardSelectedIcon from '../../assests/images/dashboardSelected.svg';
import PatientRecordIcon from '../../assests/images/patientRecord.svg';
import PatientRecordSelectedIcon from '../../assests/images/patientRecordSelected.svg';
import AccountIcon from '../../assests/images/myAccount.svg';
import AccountSelectedIcon from '../../assests/images/myAccountSelected.svg';
import "./styles.css";

const Menu = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const drawerList = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className="drawerContent"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" padding={2}>
        <Typography variant="h6" style={{ color: '#0820b4' }}>Menu</Typography>
        <IconButton onClick={toggleDrawer(false)} style={{ color: '#0820b4' }}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon>
            <img src={location.pathname === "/dashboard" ? DashboardSelectedIcon : DashboardIcon} alt="Dashboard" style={{ width: "100%", height: "100%" }} />
          </ListItemIcon>
        </ListItem>
        <ListItem button component={Link} to="/patientrecord">
          <ListItemIcon>
            <img src={location.pathname === "/patientrecord" ? PatientRecordSelectedIcon : PatientRecordIcon} alt="Patient Record" style={{ width: "100%", height: "100%" }} />
          </ListItemIcon>
        </ListItem>
        <ListItem button component={Link} to="/account">
          <ListItemIcon>
            <img src={location.pathname === "/account" ? AccountSelectedIcon : AccountIcon} alt="Account" style={{ width: "100%", height: "100%" }} />
          </ListItemIcon>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className="menuBar">
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList}
      </Drawer>
    </div>
  );
}

export default Menu;
