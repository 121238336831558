import React from "react";
import { Divider } from "@mui/material";
import "./styles.css";

const InformationCard = ({heading, number, time, percentage}) => {
  return (
    <div className="informationCard">
        <div className="information heading">{heading}</div>
        <Divider sx={{background: "rgba(8, 32, 180, 0.28)"}}/>
        <div className="numberPercentage">
        <div className="information number">{number}</div>
        <p className="percentage">{heading === "Total DR Positive Patients" || heading === "Total Referable Patients" ? `(${percentage}%)` : null}</p>
        </div>
        <div className="information time">{time}</div>
    </div>
  )
}

export default InformationCard