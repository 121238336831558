import React from "react";
import { Divider, IconButton, Typography } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import "./styles.css";
import { nextSteps } from "../../utlis";

// const handleNextDate = (left, right) => {
//     const currentDate = new Date();
//     let nextStepDate = new Date();
//     let message = "";
//     if(
//         left === "Hazy image (due to cataract or significant corneal opacity)" ||
//         right === "Hazy image (due to cataract or significant corneal opacity)"||
//         left === "Severe Diabetic Retinopathy" ||
//         right === "Severe Diabetic Retinopathy" ||
//         left === "Proliferative Diabetic Retinopathy" ||
//         right === "Proliferative Diabetic Retinopathy"
//     ) {
//         nextStepDate.setMonth(currentDate.getMonth() + 3);
//         message = "Referral required immediately  and ask for a follow up visit after 3 Months on";
//     }
//     else if (left === "Moderate Diabetic Retinopathy" || right === "Moderate Diabetic Retinopathy") {
//         nextStepDate.setMonth(currentDate.getMonth() + 6);
//         message = "Referral required within 3-6 months and ask for a follow up visit after 6 Months on";
//     }
//     else if (
//         left === "No Diabetic Retinopathy" ||
//         right === "No Diabetic Retinopathy" ||
//         left === "Mild Diabetic Retinopathy" ||
//         right === "Mild Diabetic Retinopathy"
//     ) {
//         nextStepDate.setFullYear(currentDate.getFullYear() + 1);
//         message = "Referral not required and ask for a follow up visit after a year on";
//     }

//     const formattedDate = nextStepDate.toDateString().split(' ').slice(1).join(' ');

//     return { date: formattedDate, message };
// };

const PatientImageInference = ({formik, inference, handleImageEditClick}) => {
    const {date, message} = nextSteps(inference.left, inference.right);

    const eyeInferenceDiv = (inf) => {
        if(inf === "No Diabetic Retinopathy") {
            return `eyeInferenceDivNoDR`;
        }
        if (inf === "Mild Diabetic Retinopathy") {
            return `eyeInferenceDivMildDR`;
        }
        if (inf === "Moderate Diabetic Retinopathy") {
            return `eyeInferenceDivModDR`;
        }
        if(inf === "Proliferative Diabetic Retinopathy") {
            return `eyeInferenceDivProDR`;
        }
        if(inf === "Severe Diabetic Retinopathy") {
            return `eyeInferenceDivSevDR`;
        }
        if(inf === "Hazy image (due to cataract or significant corneal opacity)") {
            return `eyeInferenceDivHazy`;
        }
    };

    const eyeInferenceText = (inf) => {
        if(inf === "No Diabetic Retinopathy") {
            return `eyeInferenceTextNoDR`;
        }
        else if (inf === "Mild Diabetic Retinopathy") {
            return `eyeInferenceTextMildDR`;
        }
        else if (inf === "Moderate Diabetic Retinopathy") {
            return `eyeInferenceTextModDR`;
        }
        else if(inf === "Proliferative Diabetic Retinopathy") {
            return `eyeInferenceTextProDR`;
        }
        else if(inf === "Severe Diabetic Retinopathy") {
            return `eyeInferenceTextSevDR`;
        }
        if(inf === "Hazy image (due to cataract or significant corneal opacity)") {
            return `eyeInferenceTextHazy`;
        }
    };

    const requiredInferenceDiv = (inf) => {
        if(inf === "Required") {
            return "reqInfDiv";
        }
        else if(inf === "Not Required") {
            return "notRegInfDiv";
        }
    };

    const requiredInferenceText = (inf) => {
        if(inf === "Required") {
            return "reqInfText";
        }
        else if (inf === "Not Required") {
            return "notReqInfText";
        }
    };

    const ImageDiv = (inf) => {
        if(inf === "No Diabetic Retinopathy") {
            return `imageDivNoDR`;
        }
        if (inf === "Mild Diabetic Retinopathy") {
            return `imageDivMildDR`;
        }
        if (inf === "Moderate Diabetic Retinopathy") {
            return `imageDivModDR`;
        }
        if(inf === "Proliferative Diabetic Retinopathy") {
            return `imageDivProDR`;
        }
        if(inf === "Severe Diabetic Retinopathy") {
            return `imageDivSevDR`;
        }
        if(inf === "Hazy image (due to cataract or significant corneal opacity)") {
            return `imageDivHazy`;
        }
    };

  return (
    <div>
        <div style={{ display: "flex", justifyContent: "space-between", padding: "0 2%" }}>
            <div>
                <div style={{display: "flex"}}>
                    <Typography fontWeight={600} fontSize="20px" color="#051572">Left Eye</Typography>
                    <IconButton onClick={handleImageEditClick}>
                        <EditOutlinedIcon fontSize="small" sx={{color: "#051572"}}/>
                    </IconButton>
                </div>
                <div style={{
                    width: "25vw",
                    height: "32vh",
                    backgroundColor: "black",
                    border: "2px solid",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 4px 1px rgba(0, 133, 13, 0.09), 0px 0px 6px 2px rgba(56, 142, 60, 0.26)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    }}
                    className={ImageDiv(inference.left)}
                >
                    <img src={URL.createObjectURL(formik.values.image_file.left.image)} style={{height: "90%", width: "90%"}} />
                </div>
                <div style={{padding: "2% 0"}}>
                    <Typography fontWeight="bold" fontSize="16px">AI Generated Inference:</Typography>
                    <div
                        className={eyeInferenceDiv(inference.left)}
                        style={{
                            borderRadius: "8px",
                            border: "1px solid",
                            display: "flex",
                            justifyContent: "center",
                            width: "fit-content",
                            padding: "1.5%"
                        }}
                    >
                        <Typography
                            className={eyeInferenceText(inference.left)}
                            fontWeight={400}
                            fontSize="16px"
                        >
                            {inference.left === "Hazy image (due to cataract or significant corneal opacity)" ? "Hazy Image (No AI Inference)" : inference.left}
                        </Typography>
                    </div>
                </div>
            </div>
            <Divider orientation="vertical" variant="middle" flexItem />
            <div>
                <div style={{display: "flex"}}>
                    <Typography fontWeight={600} fontSize="20px" color="#051572">Right Eye</Typography>
                    <IconButton onClick={handleImageEditClick}>
                        <EditOutlinedIcon fontSize="small" sx={{color: "#051572"}}/>
                    </IconButton>
                </div>
                <div style={{
                    width: "25vw",
                    height: "32vh",
                    backgroundColor: "black",
                    border: "2px solid",
                    borderRadius: "8px",
                    boxShadow: "0px 2px 4px 1px rgba(0, 133, 13, 0.09), 0px 0px 6px 2px rgba(56, 142, 60, 0.26)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    }}
                    className={ImageDiv(inference.right)}
                >
                    <img src={URL.createObjectURL(formik.values.image_file.right.image)} style={{height: "90%", width: "90%"}} />
                </div>
                <div style={{padding: "2% 0"}}>
                    <Typography fontWeight="bold" fontSize="16px">AI Generated Inference:</Typography>
                    <div
                        className={eyeInferenceDiv(inference.right)}
                        style={{
                            borderRadius: "8px",
                            border: "1px solid",
                            display: "flex",
                            justifyContent: "center",
                            width: "fit-content",
                            padding: "1.5%"
                        }}
                    >
                        <Typography
                            className={eyeInferenceText(inference.right)}
                            fontWeight={400}
                            fontSize="16px"
                        >
                            {inference.right === "Hazy image (due to cataract or significant corneal opacity)" ? "Hazy Image (No AI Inference)" : inference.right}
                        </Typography>
                    </div>
                </div>
            </div>
        </div>
        <div style={{ padding: "1% 2%" }}>
            <div>
                <Typography fontWeight="bold" fontSize="16px">AI Generated Analysis</Typography>
                <Typography color="#404040" fontWeight={400} fontSize="16px">
                    {inference.left} in the left eye, {inference.right} in the right eye. further medical examination is suggested.
                </Typography>
            </div>
        </div>
        <div style={{ padding: "1% 2%" }}>
            <div>
                <Typography fontWeight="bold" fontSize="16px">AI Generated Referral Advice:</Typography>
                <div
                    className={requiredInferenceDiv(inference.req)}
                    style={{
                        borderRadius: "8px",
                        width: "fit-content",
                        padding: "1%"
                    }}
                >
                    <Typography fontSize="16px" fontWeight={400} className={requiredInferenceText(inference.req)}>{inference.req}</Typography>
                </div>
            </div>
        </div>
        <div style={{ padding: "1% 2%" }}>
            <div>
                <Typography fontSize="16px" fontWeight="bold">Next Steps</Typography>
                <Typography color="#404040" fontSize="15px" fontWeight={400} display="flex" gap="3px" whiteSpace="nowrap">
                    {message}
                    <Typography fontWeight={700} sx={{textDecorationLine: "underline"}}>
                        {date},
                    </Typography>
                     and suggest them to monitor their sugar level.
                </Typography>
            </div>
        </div>
    </div>
  )
}

export default PatientImageInference