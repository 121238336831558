import React, { useEffect, useState } from "react";
import { Divider, IconButton, Typography } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import axios from "axios";
import config from "../../config";

const PatientDetailsInference = ({formik, handlePatientDetailsEditClick}) => {
    const [visitNumber, setVisitNumber] = useState(null);
    useEffect(() => {
        const header = {"Authorization": "Token "+localStorage.getItem("optha-token")};
        axios
        .get(`${config.API_BASE_URL}/api/reports/getreport/?patient_id=${localStorage.getItem("patient_id")}`, {headers: header})
        .then((response) => {
            setVisitNumber(response.data.count);
        })
        .catch((error) => console.log(error));
    }, [])
  return (
    <div style={{padding: "0.75% 0"}}>
        <div style={{display: "inline-flex", padding: "0 5%"}}>
            <Typography fontWeight={400} fontSize="28px" color="#0D1E83">{formik.values.pat_info.name}</Typography>
            <IconButton onClick={handlePatientDetailsEditClick}>
                <EditOutlinedIcon fontSize="small" sx={{color: "#0D1E83"}} />
            </IconButton>
        </div>
        <div style={{display: "flex", justifyContent: "space-between", padding: "0 5%"}}>
            <Typography textTransform="capitalize" fontWeight={400} fontSize="16px" color="#0D1E83">
                {`${formik.values.pat_info.age} years | ${formik.values.pat_info.gender} | Visit No. ${visitNumber+1}`}
            </Typography>
            <Typography display="flex" fontSize="16px" fontWeight={600} color="#0D1E83">
                Visit Date:
                <Typography>{new Date().toDateString()}</Typography>
            </Typography>
        </div>
        <Divider sx={{background: "rgba(13, 30, 131, 0.22)", margin: "1% 5%"}}/>
        <div style={{display: "flex", justifyContent: "space-between", padding: "0 5%"}}>
            <Typography display="flex" fontWeight="bold" fontSize="16px" color="#404040">
                Patient ID:
                <Typography fontWeight={400}>{formik.values.pat_info.uniqueId.idValue === "" ? "N/A" : formik.values.pat_info.uniqueId.idValue}</Typography>
            </Typography>
            <Typography display="flex" fontWeight="bold" fontSize="16px" color="#404040">
                Phone Number:
                <Typography fontWeight={400}>+91 {formik.values.pat_info.number === "" ? "N/A" : formik.values.pat_info.number}</Typography>
            </Typography>
            <Typography display="flex" fontWeight="bold" fontSize="16px" color="#404040" gap="1">
                Referred by:
                <Typography fontWeight={400} textTransform="capitalize">{formik.values.pat_info.referredBy.docName === "" ? "NA" : formik.values.pat_info.referredBy.docName}</Typography>
            </Typography>
        </div>
    </div>
  )
};

export default PatientDetailsInference