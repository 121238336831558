// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recordsDiv {
    padding: 2.5%;
    position: relative;
}

.searchDiv {
    display: flex;
    justify-content: center;
}

.searchBar {
    display: flex;
    align-items: center;
    width: 28vw;
    padding: 2px 4px;
    border-radius: 9px;
    border: 1px solid rgba(148, 148, 148, 0.67);
    background: var(--white, #FFF);
}

.filterDiv {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.patientRecordsGrid {
    width: 100%;
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 7.5%;
}`, "",{"version":3,"sources":["webpack://./src/components/patientRecords/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,2CAA2C;IAC3C,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,oBAAoB;AACxB","sourcesContent":[".recordsDiv {\n    padding: 2.5%;\n    position: relative;\n}\n\n.searchDiv {\n    display: flex;\n    justify-content: center;\n}\n\n.searchBar {\n    display: flex;\n    align-items: center;\n    width: 28vw;\n    padding: 2px 4px;\n    border-radius: 9px;\n    border: 1px solid rgba(148, 148, 148, 0.67);\n    background: var(--white, #FFF);\n}\n\n.filterDiv {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.patientRecordsGrid {\n    width: 100%;\n    padding: 2% 0;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    padding-bottom: 7.5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
