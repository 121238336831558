import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const PatientHealthInference = ({formik}) => {
    const HealthInfoHeaders = [
        {header: "Prediagnosed Diabetes:",
        value: formik.values.health_info.preDiagnosedDiabetes === "" ? "N/A" : formik.values.health_info.preDiagnosedDiabetes
        },
        {header: "Duration of Diabetes:",
        value: formik.values.health_info.durationDiabetesDetection === "" ? "N/A" : `${formik.values.health_info.durationDiabetesDetection} Years`
        },
        {header: "Diabetes Type:",
        value: formik.values.health_info.diabetesType === "" ? "N/A" : formik.values.health_info.diabetesType
        },
        {header: "Hb A1 C Level:",
        value: formik.values.health_info.HbA1CLevel === "" ? "N/A" : `${formik.values.health_info.HbA1CLevel} mg/dL`
        },
        {header: "Blood Pressure:",
        value: formik.values.health_info.bloodPressure === "" ? "N/A" : `${formik.values.health_info.bloodPressure} mm/Hg`
        },
        {header: "Visual Acuity:",
        value: `Left: ${formik.values.health_info.visualAcuity.left === "" ? "N/A" : formik.values.health_info.visualAcuity.left}, Right: ${formik.values.health_info.visualAcuity.right === "" ? "N/A" : formik.values.health_info.visualAcuity.right}`
        },
        {header: "Other complications:",
        value: Array.isArray(formik.values.health_info.otherComplications) ? formik.values.health_info.otherComplications.join(', '): ""
        },
        {header: "Family History of Diabetes:",
        value: Array.isArray(formik.values.health_info.familyHistory) ? formik.values.health_info.familyHistory.join(', '): ""
        },
        {header: "Notes:",
        value: `${formik.values.health_info.notes}`
        },
    ];
  return (
    <Box sx={{padding: "20px"}}>
        <Grid container spacing={2} columns={9}>
            {HealthInfoHeaders.map((item, index) => (
                <Grid item xs={3} spacing={2} key={index}>
                    <Typography sx={{fontSize: "16px", fontWeight: "bold", color: "#110202"}}>{item.header}</Typography>
                    <Typography textTransform="capitalize">{item.value}</Typography>
                </Grid>
            ))}
        </Grid>
    </Box>
  );
};

export default PatientHealthInference