export function nextSteps (left, right) {
    const currentDate = new Date();
    let nextStepDate = new Date();
    let message = "";
    if(
        left === "Hazy image (due to cataract or significant corneal opacity)" ||
        right === "Hazy image (due to cataract or significant corneal opacity)"||
        left === "Severe Diabetic Retinopathy" ||
        right === "Severe Diabetic Retinopathy" ||
        left === "Proliferative Diabetic Retinopathy" ||
        right === "Proliferative Diabetic Retinopathy"
    ) {
        nextStepDate.setMonth(currentDate.getMonth() + 3);
        message = "Referral required immediately  and ask for a follow up visit after 3 Months";
    }
    else if (left === "Moderate Diabetic Retinopathy" || right === "Moderate Diabetic Retinopathy") {
        nextStepDate.setMonth(currentDate.getMonth() + 6);
        message = "Referral required within 3-6 months and ask for a follow up visit after 6 Months";
    }
    else if (
        left === "No Diabetic Retinopathy" ||
        right === "No Diabetic Retinopathy" ||
        left === "Mild Diabetic Retinopathy" ||
        right === "Mild Diabetic Retinopathy"
    ) {
        nextStepDate.setFullYear(currentDate.getFullYear() + 1);
        message = "Referral not required and ask for a follow up visit after a year";
    }

    const formattedDate = nextStepDate.toDateString().split(' ').slice(1).join(' ');

    return { date: formattedDate, message };
};

export const handleSetDR = (classification, gradability) => {
    if (
        gradability?.predicted_class_name === "Non Gradable" &&
      classification === undefined
    ) {
      return "Hazy image (due to cataract or significant corneal opacity)";
    } else {
      return classification?.predicted_class_name;
    }
};

export function handleSetReferral (left, right) {
    if (
      (left === "No Diabetic Retinopathy" ||
        left === "Mild Diabetic Retinopathy") &&
      (right === "No Diabetic Retinopathy" ||
        right === "Mild Diabetic Retinopathy")
    ) {
      // console.log(left, right);
      return "Not Required";
    } else {
      // console.log(left, right);
      return "Required";
    }
};

export const RequiredRefferal = (classification) => {
  if(classification === "No Diabetic Retinopathy" || classification  === "Mild Diabetic Retinopathy") {
    return false;
  } else {
    return true;
  }
};