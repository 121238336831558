import React, { useEffect, useState } from "react";
import Header from "../header/header";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  InputBase,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./styles.css";
import PatientRecordsReport from "../patientRecordsReport/patientRecordsReport";
import FloatingButton from "../floatingButton/floatingButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import LoadingComponent from "../loadingComponent/loadingComponent";

const PatientRecords = () => {
  const navigate = useNavigate();
  const header = {
    Authorization: "Token " + localStorage.getItem("optha-token"),
  };
  const [allReports, setAllReports] = useState([]);
  const [next, setNext] = useState("");
  const [prev, setPrev] = useState("");
  const [count, setCount] = useState("");
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const startRecord = (currentPage - 1) * pageSize + 1;
  const endRecord = Math.min(currentPage * pageSize, count);
  const [getData, setGetData] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([
    "All Patients Screened",
  ]);

  const options = [
    "All Patients Screened",
    "Male Patients Screened",
    "Female Patients Screened",
    "Referable Patients",
    "Non-Referable Patients",
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    setGetData(true);
    await axios
      .get(`${config.API_BASE_URL}/api/reports/getreport/`, { headers: header })
      .then((response) => {
        setAllReports(response.data.results);
        setNext(response.data.next);
        setPrev(response.data.previous);
        setCount(response.data.count);
        setCurrentPage(1);
        setSelectedOptions(["All Patients Screened"]);
      })
      .catch((error) => {
        console.log(error);
      });
    setGetData(false);
  }

  const handleInputChange = async (event) => {
    let newValue = event.target.value;
    setSearchValue(newValue);
    let newValuetrim = newValue.trim();

    if (newValuetrim.length >= 3 && newValuetrim !== searchValue.trim()) {
      try {
        // const ids = await axios
        // .get(`${config.API_BASE_URL}/api/auth/users/?role=patient&search_param=${newValue}`, {headers: header});
        // const patient_ids = ids.data.map(obj => obj.id);

        await axios
          .get(
            `${config.API_BASE_URL}/api/reports/getreport/?search_param=${newValuetrim}`,
            { headers: header }
          )
          .then((response) => {
            setAllReports(response.data.results);
            setNext(response.data.next);
            setPrev(response.data.previous);
            setCount(response.data.count);
            setCurrentPage(1);
            setSelectedOptions(["All Patients Screened"]);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else if (newValue === "") {
      fetchData();
    }
  };

  const handleSetDR = (classification, gradability, ishazy) => {
    let left, right;
    if (
      ishazy.left
    ) {
      left = "Hazy Image (No AI Inference)";
    } else {
      left = classification?.left?.predicted_class_name;
    }
    if (
      ishazy.right
    ) {
      right = "Hazy Image (No AI Inference)";
    } else {
      right = classification?.right?.predicted_class_name;
    }

    return { left, right };
  };

  const handleNextButtonClick = async () => {
    setGetData(true);
    await axios
      .get(next, { headers: header })
      .then((response) => {
        setAllReports(response.data.results);
        setNext(response.data.next);
        setPrev(response.data.previous);
        setCount(response.data.count);
        setCurrentPage((prevPage) => prevPage + 1);
      })
      .catch((error) => console.log(error));
    setGetData(false);
  };

  const handlePrevButtonClick = async () => {
    setGetData(true);
    await axios
      .get(prev, { headers: header })
      .then((response) => {
        setAllReports(response.data.results);
        setNext(response.data.next);
        setPrev(response.data.previous);
        setCount(response.data.count);
        setCurrentPage((prevPage) => prevPage - 1);
      })
      .catch((error) => console.log(error));
    setGetData(false);
  };

  const handleFloatingClick = () => {
    navigate("/patientscreen");
  };

  const toggleDialog = () => {
    console.log("Dialog Opened or Closed");
    setIsDialogOpen(!isDialogOpen);
  };

  const selectOption = (option) => {
    if (option === "All Patients Screened") {
      setSelectedOptions([option]);
    } else {
      if (selectedOptions.includes(option)) {
        setSelectedOptions(selectedOptions.filter((item) => item !== option));
      } else {
        // Remove "All Patients Screened" if any other option is selected
        if (selectedOptions.includes("All Patients Screened")) {
          setSelectedOptions([option]);
        } else {
          setSelectedOptions([...selectedOptions, option]);
        }
      }
    }
  };

  // Function to handle API call
  const handleAPICall = async () => {
    setGetData(true);
    let apiUrl = "/api/reports/getreport/?";

    if (searchValue.trim().length >= 3) {
      apiUrl += `search_param=${searchValue.trim()}`;
    }

    if (selectedOptions.includes("All Patients Screened")) {
      //All Patients Screened, Don't add anything Just make call as it is
    } else {
      // Check if "Male" or "Female" is selected
      if (
        selectedOptions.includes("Male Patients Screened") &&
        selectedOptions.includes("Female Patients Screened")
      ) {
        // Both genders selected, don't add anything
      } else if (selectedOptions.includes("Male Patients Screened")) {
        apiUrl += "&sex=male&";
      } else if (selectedOptions.includes("Female Patients Screened")) {
        apiUrl += "&sex=female&";
      }

      // Check if "Referable" or "Non-Referable" is selected
      if (
        selectedOptions.includes("Referable Patients") &&
        selectedOptions.includes("Non-Referable Patients")
      ) {
        // Both referable options selected, don't add anything
      } else if (selectedOptions.includes("Referable Patients")) {
        apiUrl += "&referable=required&";
      } else if (selectedOptions.includes("Non-Referable Patients")) {
        apiUrl += "&referable=not-required&";
      }
    }

    // Remove the trailing "&" if it exists
    if (apiUrl.endsWith("&")) {
      apiUrl = apiUrl.slice(0, -1);
    }

    // Make the API call
    await axios
      .get(`${config.API_BASE_URL}${apiUrl}`, { headers: header })
      .then((response) => {
        // Handle the API response here
        console.log("API call success:", response.data);
        setAllReports(response.data.results);
        setNext(response.data.next);
        setPrev(response.data.previous);
        setCount(response.data.count);
        setCurrentPage(1);
      })
      .catch((error) => {
        // Handle API call errors here
        console.error("API call error:", error);
      });
    setGetData(false);
  };

  return (
    <>
      {!isMobile && <Header />}
      <div className="recordsDiv">
        <div  className="searchDiv">
          <Paper className="searchBar" sx={isMobile && {width:"100%"}}>
            <IconButton>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by Patient Name and Unique ID"
              inputProps={{ "aria-label": "search patient reports" }}
              onChange={handleInputChange}
              value={searchValue}
            />
          </Paper>
        </div>
        <div className="filterDiv">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: "4px",
              background: "#EDEDED",
              cursor: "pointer",
            }}
            onClick={() => toggleDialog()}
          >
            <div
              style={{
                background: "#011F4B",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
              }}
            >
              <FilterAltOutlinedIcon style={{ color: "#FFFFFF" }} />
            </div>
            {!isMobile && <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                padding: "4px",
              }}
            >
              {selectedOptions.join(", ")}
            </div>}
            <div>
              <Dialog open={isDialogOpen}>
                <DialogTitle
                  color="#051572"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FilterAltOutlinedIcon style={{ color: "#051572" }} />
                    Filter Patients By
                  </div>
                  <IconButton onClick={toggleDialog}>
                    <CloseOutlinedIcon sx={{ color: "#051572" }} />
                  </IconButton>
                </DialogTitle>
                <DialogContent
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  {options.map((option) => (
                    <>
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            checked={selectedOptions.includes(option)}
                            onChange={() => selectOption(option)}
                            style={{ color: "#051572" }}
                          />
                        }
                        label={option}
                        onClick={(event) => event.stopPropagation()}
                      />
                      <Divider />
                    </>
                  ))}
                </DialogContent>
                <div
                  style={{
                    display: "flex",
                    background: "#F0F1F4",
                    justifyContent: "space-evenly",
                    padding: "3.5%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      fontWeight: "600",
                      height: "100%",
                    }}
                    onClick={toggleDialog}
                  >
                    Close
                  </div>
                  <Divider orientation="vertical" flexItem />
                  <div
                    style={{
                      textAlign: "center",
                      cursor: "pointer",
                      fontWeight: "600",
                      height: "100%",
                    }}
                    onClick={handleAPICall}
                  >
                    Apply
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography color="#949494" fontWeight="300" fontSize={isMobile?"12px":"16px"}>
              {count > 0
                ? `${startRecord}-${endRecord} out of ${count}`
                : `0-${endRecord} out of ${count}`}
            </Typography>
            {prev === "" || prev === null ? (
              <IconButton disabled={true}>
                <NavigateBeforeIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handlePrevButtonClick}>
                <NavigateBeforeIcon color="#000000" />
              </IconButton>
            )}
            {next === "" || next === null ? (
              <IconButton disabled={true}>
                <NavigateNextIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleNextButtonClick}>
                <NavigateNextIcon color="#000000" />
              </IconButton>
            )}
          </div>
        </div>
        <div className="patientRecordsGrid">
          {allReports.map((report, index) => (
            <PatientRecordsReport
              key={index}
              report={report}
              inference={handleSetDR(
                report.ml_model.classification,
                report.ml_model.gradability,
                report.ml_model.ishazy
              )}
              isMobile={isMobile}
            />
          ))}
        </div>
        <FloatingButton onClick={handleFloatingClick} />
      </div>
      <LoadingComponent open={getData} text={false} />
    </>
  );
};

export default PatientRecords;
