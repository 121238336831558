// import React, { useState } from "react";
// import { Button, Container, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
// import "./styles.css";
// import loginImage from "./../../assests/images/118979-health-blue.jpg";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import config from "../../config";

// const Login = ({ setUserLoggedIn }) => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('md'));

//   const handleUsernameChange = (e) => {
//     setUsername(e.target.value);
//   };

//   const handlePasswordChange = (e) => {
//     setPassword(e.target.value);
//   };

//   const handleLogin = async () => {
//     try {
//       const data = {
//         username: username,
//         password: password,
//       };

//       const response = await axios.post(`${config.API_BASE_URL}/api/auth/login/`, data);
//       if (response.data.token) {
//         const data = JSON.stringify(response.data.user_profile);
//         localStorage.setItem("user_profile", data);
//         localStorage.setItem("optha-token", response.data.token);
//         setUserLoggedIn(true);
//         navigate("/dashboard");
//       }
//     } catch (error) {
//       alert(error?.response?.data?.error);
//       console.error('Login failed:', error);
//     }
//   };

//   return (
//     <div style={{ height: isMobile?"":"90%", display: "flex", justifyContent: "center", alignItems: "center" }}>
//       <Container
//         className="container"
//         sx={{
//           width: { xs: "90vw", md: "90vw" },
//           height: { xs: "auto", md: "52vh" },
//           display: "flex",
//           flexDirection: { xs: "column", md: "row" },
//           border: "1px solid var(--box-stroke, rgba(0, 0, 0, 0.05))",
//           borderRadius: "4px",
//           padding: "24px"
//         }}
//       >
//         <div className="login-image">
//           <img src={loginImage} style={{ width: "100%", height: "100%" }} alt="Login" />
//         </div>
//         <div className="login-form">
//           <div style={{ width: "100%", height: "75%" }}>
//             <div style={{ padding: "16px" }}>
//               <Typography color="var(--dark-gray, #181818)" fontSize="2.25vh" fontWeight="600" lineHeight="137%">Log into your Account</Typography>
//             </div>
//             <div style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "16px" }}>
//               <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
//                 <Typography color="var(--black, #404040)" fontSize="1.75vh" fontWeight="500" lineHeight="110%">User ID / Phone Number</Typography>
//                 <TextField
//                   sx={{ width: "22vw" }}
//                   placeholder="Enter User ID or Phone Number"
//                   value={username}
//                   onChange={handleUsernameChange}
//                 />
//               </div>
//               <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
//                 <Typography color="var(--black, #404040)" fontSize="1.75vh" fontWeight="500" lineHeight="110%">Re-Type New password</Typography>
//                 <TextField
//                   type="password"
//                   sx={{ width: "22vw" }}
//                   placeholder="Re-Enter New Password"
//                   value={password}
//                   onChange={handlePasswordChange}
//                 />
//               </div>
//               <div style={{ display: "flex", justifyContent: "flex-end" }}>
//                 <div style={{ display: "flex", gap: "12px" }}>
//                   <Button
//                     variant="primary"
//                     sx={{
//                       color: "#051572",
//                     }}
//                   >
//                     Forgot Password
//                   </Button>
//                   <Button
//                     variant="primary"
//                     sx={{
//                       background: "var(--gradient, linear-gradient(270deg, #051572 0%, #0820B4 100%))",
//                       boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)",
//                       color: "var(--white, #FFF)",
//                       borderRadius: "8px",
//                     }}
//                     onClick={handleLogin}
//                   >
//                     Login
//                   </Button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </Container>
//     </div>
//   );
// };

// export default Login;

import React, { useState } from "react";
import { Button, Container, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import "./styles.css";
import loginImage from "./../../assests/images/118979-health-blue.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";

const Login = ({ setUserLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    try {
      const data = { username, password };
      const response = await axios.post(`${config.API_BASE_URL}/api/auth/login/`, data);

      if (response.data.token) {
        const userProfile = JSON.stringify(response.data.user_profile);
        localStorage.setItem("user_profile", userProfile);
        localStorage.setItem("optha-token", response.data.token);
        setUserLoggedIn(true);
        navigate("/dashboard");
      }
    } catch (error) {
      alert(error?.response?.data?.error);
      console.error('Login failed:', error);
    }
  };

  return (
    <div style={{ height: isMobile ? "auto" : "90%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Container
        className={`container ${isMobile ? 'container-mobile' : ''}`}
        sx={{
          width: { xs: "90vw", md: "70vw" },
          height: { xs: "auto", md: "52vh" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          border: "1px solid var(--box-stroke, rgba(0, 0, 0, 0.05))",
          borderRadius: "4px",
          padding: "24px"
        }}
      >
        <div className={`login-image ${isMobile ? 'login-image-mobile' : ''}`}>
          <img src={loginImage} style={{ width: "100%", height: "100%" }} alt="Login" />
        </div>
        <div className="login-form">
          <div style={{ width: "100%", height: "75%" }}>
            <div style={{ padding: "16px" }}>
              <Typography color="var(--dark-gray, #181818)" fontSize="2.25vh" fontWeight="600" lineHeight="137%">Log into your Account</Typography>
            </div>
            <div style={{ padding: "16px", display: "flex", flexDirection: "column", gap: "16px" }}>
              <div className={`input-group ${isMobile ? 'input-group-mobile' : ''}`}>
                <Typography color="var(--black, #404040)" fontSize="1.75vh" fontWeight="500" lineHeight="110%">User ID / Phone Number</Typography>
                <TextField
                  sx={{ width: { xs: "100%", md: "22vw" } }}
                  placeholder="Enter User ID or Phone Number"
                  value={username}
                  onChange={handleUsernameChange}
                />
              </div>
              <div className={`input-group ${isMobile ? 'input-group-mobile' : ''}`}>
                <Typography color="var(--black, #404040)" fontSize="1.75vh" fontWeight="500" lineHeight="110%">Re-Type New password</Typography>
                <TextField
                  type="password"
                  sx={{ width: { xs: "100%", md: "22vw" } }}
                  placeholder="Re-Enter New Password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </div>
              <div className={`button-group ${isMobile ? 'button-group-mobile' : ''}`}>
                <Button
                  variant="primary"
                  sx={{ color: "#051572" }}
                >
                  Forgot Password
                </Button>
                <Button
                  variant="primary"
                  sx={{
                    background: "var(--gradient, linear-gradient(270deg, #051572 0%, #0820B4 100%))",
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08), 0px 0px 6px 0px rgba(0, 0, 0, 0.02)",
                    color: "var(--white, #FFF)",
                    borderRadius: "8px",
                  }}
                  onClick={handleLogin}
                >
                  Login
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Login;
