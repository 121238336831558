import React from "react";
import { Container, Typography } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';


const ImageGood = () => {
  return (
    <Container sx={{display: "flex", alignItems: "center", background: "#E5F2FF", borderRadius: "4px", border: "1px solid var(--box-stroke, rgba(0, 0, 0, 0.05))", padding: "8px", gap: "8px"}}>
        <div style={{background: "#DCFFE0", borderRadius: "50%", display: "flex", justifyContent: "center", alignContent: "center"}}>
            <CheckRoundedIcon sx={{fill: "#2EC821"}} fontSize="small"/>
        </div>
        <Typography fontSize="12px" fontWeight={400}>Great Job! The Image is good to go!</Typography>
    </Container>
  )
}

export default ImageGood