import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography, useTheme, useMediaQuery } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ImageUploaded from "./imageUploaded";
import ImageGood from "./ImageGood";
import ImageBad from "./ImageBad";
import ImageConfirm from "./ImageConfirm";
import ProceedNotGradable from "./ProceedNotGradable";
import axios from "axios";
import config from "../../config";

const options = [
    {label: "Hazy image due to Cataract", value: "Hazy image due to Cataract"},
    {label: "Gradable image (the model giving wrong inference)", value: "Gradable image (the model giving wrong inference)"},
    {label: "Other Reasons", value: "Other Reasons"},
];

const handleImageValueSet = (file, eye, formik) => {
    if(eye === "left") {
        if(Array.isArray(file) && file.length>0) {
            formik.setFieldValue("image_file.left.image", file[0].file);
        }
        else {
            formik.setFieldValue("image_file.left.image", file);
        }
    }
    else if(eye === "right") {
        if(Array.isArray(file) && file.length>0) {
            formik.setFieldValue("image_file.right.image", file[0].file);
        }
        else {
            formik.setFieldValue("image_file.right.image", file);
        }
    }
};

registerPlugin(FilePondPluginFileValidateType);

const ImageUpload = ({handleBack, handleSubmit, formik}) => {
    const header = {
        "Authorization": "Token "+localStorage.getItem("optha-token")
    };
    const [leftEye, setLeftEye] = useState(
        Object.keys(formik.values.image_file.left.image).length === 0 ? null : formik.values.image_file.left.image
    );
    const [rightEye, setRightEye] = useState(
        Object.keys(formik.values.image_file.right.image).length === 0 ? null : formik.values.image_file.right.image
    );
    const [leftGradable, setLeftGradable] = useState();
    const [proceedNotLeftGradable, setProceedNotLeftGradable] = useState(false);
    const [rightGradable, setRightGradable] = useState();
    const [proceedNotRightGradable, setProceedNotRightGradable] = useState(false);
    const [showLeftImageConfirmation, setShowLeftImageConfirmation] = useState(false);
    const [showRightImageConfirmation, setShowRightImageConfirmation] = useState(false);
    const [disabledProceed, setDisabledProceed] = useState(true);

    const handleDisabledProceed = () => {
        if(leftEye && rightEye && leftGradable && rightGradable) {
            setDisabledProceed(false);
        }
    };

    useEffect(() => {handleDisabledProceed()}, [leftEye, rightEye, leftGradable, rightGradable]);
    useEffect(() => {
        if(leftEye !== null && showLeftImageConfirmation === false) {
                let image;
                if (Array.isArray(leftEye) && leftEye.length > 0) {
                    image = leftEye[0].file;
                }
                else {
                    image = leftEye;
                }
                const formData = new FormData();
                formData.append('model_name', 'gradability_model');
                formData.append('image', image);
                axios
                .post(`${config.API_BASE_URL}/api/reports/model_inference/`, formData, {headers: header})
                .then((response) => {
                    setLeftGradable(response.data.predicted_class_name === "Non Gradable" ? "bad" : "good");
                    formik.setFieldValue("image_file.left.gradable", response.data.predicted_class_name);
                    formik.setFieldValue("image_file.left.gradability", response.data);
                })
                .catch((error) => {console.log(error)});
                handleImageValueSet(leftEye, "left", formik);
        }
        else {
            formik.setFieldValue("image_file.left.image", {});
        }
    },[leftEye, showLeftImageConfirmation]);

    useEffect(() => {
        if(rightEye && showRightImageConfirmation === false) {
            let image;
            if (Array.isArray(rightEye) && rightEye.length > 0) {
                image = rightEye[0].file;
            }
            else {
                image = rightEye;
            }
            const formData = new FormData();
            formData.append('model_name', 'gradability_model');
            formData.append('image', image);
            axios
            .post(`${config.API_BASE_URL}/api/reports/model_inference/`, formData, {headers: header})
            .then((response) => {
                setRightGradable(response.data.predicted_class_name === "Non Gradable" ? "bad" : "good");
                formik.setFieldValue("image_file.right.gradable", response.data.predicted_class_name);
                formik.setFieldValue("image_file.right.gradability", response.data);
            })
            .catch((error) => {console.log(error)});
            handleImageValueSet(rightEye, "right", formik);
        }
        else {
            formik.setFieldValue("image_file.right.image", {});
        }
    }, [rightEye, showRightImageConfirmation]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleProceed = () => {
        if(leftGradable === "bad") {
            setProceedNotLeftGradable(true);
        }
        if(rightGradable === "bad") {
            setProceedNotRightGradable(true);
        }
        if(rightGradable === "good" && leftGradable === "good") {
            handleSubmit();
        }
    };

    const handleAddLeftEye = () => {
        setShowLeftImageConfirmation(true);
    };

    const handleAddRightEye = () => {
        setShowRightImageConfirmation(true);
    };

    const handleConfirmationCloseLeft = () => {
        setShowLeftImageConfirmation(false);
        setLeftEye(null);
        setLeftGradable();
    };

    const handleConfirmationCloseRight = () => {
        setShowRightImageConfirmation(false);
        setRightEye(null);
        setRightGradable();
    };

    const handleConfirmationYes = () => {
        setShowLeftImageConfirmation(false);
        setShowRightImageConfirmation(false);
    };

    const handleProceedNotGradableClose = (eye) => {
        if(eye === "left") {setProceedNotLeftGradable(false);}
        if(eye === "right") {setProceedNotRightGradable(false);}
    };

    const handleProceedNotGradableNext = (eye) => {
        if(rightGradable === "bad" && leftGradable === "bad") {
            // console.log("Both Bad");
            if(eye==="right") {setProceedNotRightGradable(false);}
            if(eye==="left") {setProceedNotLeftGradable(false); handleSubmit();}
        }
        if (eye === "left" && leftGradable === "bad" && rightGradable === "good") {
            console.log("Inside left eye bad only");
            setProceedNotLeftGradable(false);
            handleSubmit();
        }
        if(eye === "right" && leftGradable === "good" && rightGradable === "bad") {
            console.log("Inside right eye bad only");
            setProceedNotRightGradable(false);
            handleSubmit();
        }
    }

  return (
    <div>
        <Container sx={{display: "flex", justifyContent: "space-evenly", paddingX: isMobile? "0px": "16px"}}>
        <Box sx={{border: "1px solid rgba(0, 0, 0, 0.05)", borderRadius: "8px", display: isMobile?"flex":"", flexDirection:isMobile?"column":"", gap: isMobile? "5px":""}}>
                <Container sx={{background: "#EAF4FA", padding: "1% 0"}}>
                    <Typography display="flex" alignItems="center" color="#0D1E83" fontSize="2.3vh" fontWeight="700" justifyContent="center">
                        R
                        <VisibilityOutlinedIcon sx={{height: "3em", width: "3em"}}/>
                    </Typography>
                    <Typography display="flex" justifyContent="center" color="#949494" fontWeight="400">
                        Right Patient Eye
                    </Typography>
                </Container>
                <Container sx={{ width: isMobile? "40vw": "24vw", minHeight: isMobile?"25vh":"20vh", padding:  isMobile?"0px":"12px"}}>
                    <Box sx={{border: "1px dashed #B8B3B4", borderRadius: "4px", padding: isMobile?"0px":"5px", height: isMobile?"100%":"", background:isMobile && !rightEye?"#F0EEEE":""}}>
                        {rightEye ?
                            <ImageUploaded
                                files={rightEye}
                                resetFile={setRightEye}
                                resetGradable={setRightGradable}
                                eye={"Right Patient Eye"}
                                setDisabledProceed={setDisabledProceed}
                            />
                            :
                            <FilePond
                                file={rightEye}
                                required
                                credits={false}
                                acceptedFileTypes={['image/*']}
                                checkValidity={true}
                                labelIdle= {isMobile? '<span class="filepond--label-action">Select Eye Image</span><br/><span class="filpond--support">Supports: Jpeg, Png, Jpg</span>' :'Drop image here, or <span class="filepond--label-action">Browse</span><br/><span class="filpond--support">Supports: Jpeg, Png, Jpg</span>'}
                                onupdatefiles={(fileItems) => {
                                    if (fileItems.length === 0) {
                                        console.log("No files selected");
                                      } else if (fileItems[0].file && fileItems[0].file.type.startsWith("image/")) {
                                        // Use fileItems[0].file to create a URL or perform other operations
                                        setRightEye(fileItems);
                                        handleAddRightEye();
                                      } else if (fileItems[0].error) {
                                        console.error("FilePond Error:", fileItems[0].error);
                                      }
                                }}
                            />
                        }
                    </Box>
                </Container>
                <Container sx={{  width: isMobile? "38vw": "22vw", paddingBottom: "12px"}}>
                    { rightGradable === "good" && <ImageGood />}
                    { rightGradable === "bad" && <ImageBad eye={"Right Eye"}/>}
                </Container>
                {showRightImageConfirmation &&
                <ImageConfirm open={showRightImageConfirmation} eye="right" heading={"Right Eye Image"} file={rightEye} handleConfirmationClose={handleConfirmationCloseRight} handleConfirmationYes={handleConfirmationYes}/>}
            </Box>
            <Box sx={{border: "1px solid rgba(0, 0, 0, 0.05)", borderRadius: "8px", display: isMobile?"flex":"", flexDirection:isMobile?"column":"", gap: isMobile? "5px":""}}>
                <Container sx={{background: "#EAF4FA", padding: "1% 0"}}>
                    <Typography display="flex" alignItems="center" color="#0D1E83" fontSize="2.3vh" fontWeight="700" justifyContent="center">
                        <VisibilityOutlinedIcon sx={{height: "3em", width: "3em"}}/>
                        L
                    </Typography>
                    <Typography display="flex" justifyContent="center" color="#949494" fontWeight="400">
                        Left Patient Eye
                    </Typography>
                </Container>
                <Container sx={{ width: isMobile? "40vw": "24vw", minHeight: isMobile?"25vh":"20vh", padding:  isMobile?"0px":"12px"}}>
                    <Box sx={{border: "1px dashed #B8B3B4", borderRadius: "4px", padding: isMobile?"0px":"5px", height: isMobile?"100%":"", background:isMobile && !leftEye?"#F0EEEE":""}}>
                        {leftEye ?
                            <ImageUploaded
                                files={leftEye}
                                resetFile={setLeftEye}
                                resetGradable={setLeftGradable}
                                eye={"Left Patient Eye"}
                                setDisabledProceed={setDisabledProceed}
                            />
                            :
                            <FilePond
                                file={leftEye}
                                required
                                credits={false}
                                acceptedFileTypes={['image/*']}
                                checkValidity={true}
                                labelIdle= {isMobile? '<span class="filepond--label-action">Select Eye Image</span><br/><span class="filpond--support">Supports: Jpeg, Png, Jpg</span>' :'Drop image here, or <span class="filepond--label-action">Browse</span><br/><span class="filpond--support">Supports: Jpeg, Png, Jpg</span>'}
                                onupdatefiles={(fileItems) => {
                                    if (fileItems.length === 0) {
                                        console.log("No files selected");
                                      } else if (fileItems[0].file && fileItems[0].file.type.startsWith("image/")) {
                                        // Use fileItems[0].file to create a URL or perform other operations
                                        setLeftEye(fileItems);
                                        handleAddLeftEye();
                                      } else if (fileItems[0].error) {
                                        console.error("FilePond Error:", fileItems[0].error);
                                      }
                                }}
                            />
                        }
                    </Box>
                </Container>
                <Container sx={{  width: isMobile? "38vw": "22vw", paddingBottom: "12px"}}>
                    {leftGradable === "good" && <ImageGood />}
                    {leftGradable === "bad" && <ImageBad eye={"Left Eye"}/>}
                </Container>
                {showLeftImageConfirmation &&
                <ImageConfirm open={showLeftImageConfirmation} eye="left" heading={"Left Eye Image"} file={leftEye} handleConfirmationClose={handleConfirmationCloseLeft} handleConfirmationYes={handleConfirmationYes} />}
            </Box>
        </Container>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "16px", gap: "2.5%"}}>
            <Button onClick={handleBack} sx={{color: "#0D1E83"}}>Return</Button>
            <Button
                    variant='contained'
                    disabled={disabledProceed}
                    borderRadius="8px"
                    fontWeight="400"
                    fontSize="16px"
                    style={{
                        padding: "12px 24px",
                        background:
                        disabledProceed ?
                        "var(--grey, #F4EFF0)" :
                        'linear-gradient(270deg, #051572 7.32%, #0820B4 92.68%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
                        border:
                        disabledProceed ?
                        '1px solid rgba(125, 121, 123, 0.50)' :
                        '1px solid #7D797B80',
                    }}
                    endIcon={<ArrowForwardIcon />}
                    onClick={handleProceed}
                >
                    Proceed
                </Button>
        </div>
        {proceedNotLeftGradable &&
        <ProceedNotGradable
            formik={formik}
            eye = "left"
            open={proceedNotLeftGradable}
            question="Patients Left Eye"
            options={options}
            handleProceedNotGradableClose={(eye) => handleProceedNotGradableClose(eye="left")}
            handleProceedNotGradableNext={(eye) => handleProceedNotGradableNext(eye="left")}
        />
        }
        {proceedNotRightGradable &&
        <ProceedNotGradable
            formik={formik}
            eye = "right"
            open={proceedNotRightGradable}
            question="Patients Right Eye"
            options={options}
            handleProceedNotGradableClose={(eye) => handleProceedNotGradableClose(eye="right")}
            handleProceedNotGradableNext={(eye) => handleProceedNotGradableNext(eye="right")}
        />
        }
    </div>
  )
}

export default ImageUpload