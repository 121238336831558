import React from "react";
import "./styles.css";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

const Header = () => {
  return (
    <div className="headerBar">
        <div style={{display: "flex", gap: "2vw"}}>
            <Typography component={Link} to="/dashboard" className={`${window.location.pathname === '/dashboard' ? "activeLinkText" : "navLinkText1"}`}>Dashboard</Typography>
            <Typography component={Link} to="/patientrecord" className={`${window.location.pathname === '/patientrecord' ? "activeLinkText" : "navLinkText1"}`}>Patient Record</Typography>
        </div>
        <div className="headerBarAccountDiv" style={{display: "flex", justifyItems: "center", alignItems: "center", gap: "10px"}} >
            <Typography component={Link} to="/account" className={`${window.location.pathname === '/account' ? "activeLinkText" : "navLinkText2"}`}>Account</Typography>
            {/* <img src={Account} alt="Account Icon" /> */}
            <AccountCircleOutlinedIcon className={`${window.location.pathname === '/account' ? "activeLinkText" : "navLinkText2"}`} sx={{ fontSize: "xx-large" }}/>
        </div>
    </div>
  )
}

export default Header