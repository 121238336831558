// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.informationCard {
    border-radius: 10px;
    border: 1px solid var(--15-stroke, rgba(0, 0, 0, 0.15));
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    padding: 2.5% 5%;
}

.information {
    color: #051572;
    line-height: 150%;
}

.heading {
    font-size: 3vh;
    font-weight: 300;
    margin-top: 1.5vh;
    margin-bottom: 0.5vh;
    text-align: left;
}

.numberPercentage {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 0.5rem;
}

.number {
    font-size: 4.6vh;
    font-weight: 600;
    text-align: center;
}

.percentage {
    color: #8A0000;
    font-weight: 500;
    line-height: 100%;
}

.time {
    font-size: 1.25vh;
    font-weight: 400;
    color: rgba(5, 21, 114, 0.37);
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/informationCard/styles.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,uDAAuD;IACvD,+CAA+C;IAC/C,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,oBAAoB;IACpB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,6BAA6B;IAC7B,kBAAkB;AACtB","sourcesContent":[".informationCard {\n    border-radius: 10px;\n    border: 1px solid var(--15-stroke, rgba(0, 0, 0, 0.15));\n    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.06);\n    display: flex;\n    flex-direction: column;\n    padding: 2.5% 5%;\n}\n\n.information {\n    color: #051572;\n    line-height: 150%;\n}\n\n.heading {\n    font-size: 3vh;\n    font-weight: 300;\n    margin-top: 1.5vh;\n    margin-bottom: 0.5vh;\n    text-align: left;\n}\n\n.numberPercentage {\n    display: flex;\n    justify-content: center;\n    align-items: flex-end;\n    gap: 0.5rem;\n}\n\n.number {\n    font-size: 4.6vh;\n    font-weight: 600;\n    text-align: center;\n}\n\n.percentage {\n    color: #8A0000;\n    font-weight: 500;\n    line-height: 100%;\n}\n\n.time {\n    font-size: 1.25vh;\n    font-weight: 400;\n    color: rgba(5, 21, 114, 0.37);\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
