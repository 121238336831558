// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkboxDiv {
    background: var(--bg-blue, #F9FCFF);
    border: 1px solid #0000000D;
    border-radius: 4px;
    padding: 4px 8px;
    margin-top: 16px;
}

.buttonDiv {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 900px) {
    .buttonDiv {
        margin-top: 18px;
        display: flex;
        justify-content: flex-end;
    }
}

.contentButton {
    border-radius: 8px;
    padding: 12px 24px;
}


.popup {
    position: fixed;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    background: rgba(15, 15, 15, 0.67);
    transform: translate(-50%, -50%);
    z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/components/patientScreening/informedConsent.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI;QACI,gBAAgB;QAChB,aAAa;QACb,yBAAyB;IAC7B;AACJ;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;;AAGA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,kCAAkC;IAClC,gCAAgC;IAChC,aAAa;AACjB","sourcesContent":[".checkboxDiv {\n    background: var(--bg-blue, #F9FCFF);\n    border: 1px solid #0000000D;\n    border-radius: 4px;\n    padding: 4px 8px;\n    margin-top: 16px;\n}\n\n.buttonDiv {\n    margin-top: 18px;\n    display: flex;\n    justify-content: space-between;\n}\n\n@media (max-width: 900px) {\n    .buttonDiv {\n        margin-top: 18px;\n        display: flex;\n        justify-content: flex-end;\n    }\n}\n\n.contentButton {\n    border-radius: 8px;\n    padding: 12px 24px;\n}\n\n\n.popup {\n    position: fixed;\n    display: flex;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n    top: 50%;\n    left: 50%;\n    background: rgba(15, 15, 15, 0.67);\n    transform: translate(-50%, -50%);\n    z-index: 1000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
