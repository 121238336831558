// import React from 'react';
// import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
// import userProfile from "../../assests/images/Doctor.jpg";
// import "./styles.css";
// import Header from '../header/header';

// const Profile = ({userData}) => {

//     const handleLogOut = () => {
//         localStorage.clear();
//         window.location.href = "/";
//     }

//   return (
//     <>
//     <Header />
//     <div className='userAccount'>
//         <Stack direction="row" padding="10px 5%" justifyContent="space-between" alignItems="center" >
//             <Typography fontSize="28px" fontWeight="500" lineHeight="31px">
//                 Account
//             </Typography>
//         </Stack>
//         <div className='accountDetails'>
//             <Box
//                 display="flex"
//                 justifyContent="center"
//                 alignContent="center"
//                 gap={4}
//             >
//                 <Avatar alt="User Account" src={userProfile} sx={{ width: "33%", height: "40%", borderRadius: "50%" }}/>
//                 <div style={{ padding: "2%",width: "35%" ,display: "flex", flexDirection: "column", justifyContent: "space-evenly"}}>
//                     <Typography fontSize="32px" fontWeight="500" lineHeight="36px" color="#0D1E83" textTransform="capitalize">{userData.name}</Typography>
//                     <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center"}}>
//                         <div>
//                             <Typography fontSize="26px" fontWeight="320" lineHeight="28.2px" textTransform="capitalize">{userData.age}Y / {userData.sex}</Typography>
//                         </div>
//                         <div>
//                             <Typography fontSize="26px" fontWeight="320" lineHeight="28.2px">ID: {userData.user.username}</Typography>
//                         </div>
//                     </div>
//                     <Typography fontSize="26px" fontWeight="320" lineHeight="28.2px" width="max-content">{userData.metadata.hospital ? userData.metadata.hospital : "AIIMS"}</Typography>
//                     <Divider sx={{background: "rgba(13, 30, 131, 0.42)"}}/>
//                     <div className="logoutButton" onClick={handleLogOut}>Log Out</div>
//                 </div>
//             </Box>

//             {/* <Box
//                 padding="24px"
//                 border="1px solid rgba(0, 0, 0, 0.05)"
//                 borderRadius="8px"
//                 marginY="15px"
//                 minHeight="70vh"
//             >
//                 <Typography fontSize="24px" fontWeight="500" lineHeight="27.05px">Account Details</Typography>
//                 <Grid container rowSpacing={2} marginTop="1px">
//                     <Grid item xs={12} container alignItems="center">
//                         <Typography
//                             fontSize="16px"
//                             fontWeight="500"
//                             lineHeight="18.03px"
//                             component="label"
//                             htmlFor="facilitatorName"
//                             width="13vw"
//                         >
//                             Facilitator’s Name
//                         </Typography>
//                         <TextField
//                             disabled
//                             id="facilitatorName"
//                             name="facilitatortName"
//                             value={account.name}
//                             variant="outlined"
//                             sx={{ width: "27vw", backgroundColor: "rgba(0,0,0,0.05)" }}
//                         />
//                     </Grid>
//                     <Grid item xs={12} container alignItems="center">
//                         <Typography
//                             fontSize="16px"
//                             fontWeight="500"
//                             lineHeight="18.03px"
//                             component="label"
//                             htmlFor="phone"
//                             width="13vw"
//                         >
//                             Phone Number
//                         </Typography>
//                         <TextField
//                             disabled
//                             id="phone"
//                             name="phone"
//                             value={account.phone}
//                             variant="outlined"
//                             sx={{ width: "27vw" }}
//                         />
//                     </Grid>
//                     <Grid item xs={12} container alignItems="center">
//                         <FormControl component="fieldset">
//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                                 <Typography
//                                     fontSize="16px"
//                                     fontWeight="500"
//                                     lineHeight="18.03px"
//                                     component="label"
//                                     htmlFor="gender"
//                                     width="14vw"
//                                 >
//                                     Gender
//                                 </Typography>
//                                 <RadioGroup row name="gender" value={account.gender}>
//                                     <FormControlLabel value="male" control={<Radio />} label="Male" sx={{border: "1px solid rgba( 0, 0, 0, 0.05)", borderRadius: "8px", paddingRight: "10px", boxShadow: "1px 1px 10px 0 #4040400D", fontSize: "16px", fontWeight: "300"}}/>
//                                     <FormControlLabel value="female" control={<Radio />} label="Female" sx={{border: "1px solid rgba( 0, 0, 0, 0.05)", borderRadius: "8px", paddingRight: "10px", marginLeft: "4px", boxShadow: "1px 1px 10px 0 #4040400D"}}/>
//                                     <FormControlLabel value="other" control={<Radio />} label="Other" sx={{border: "1px solid rgba( 0, 0, 0, 0.05)", borderRadius: "8px", paddingRight: "10px", marginLeft: "4px", boxShadow: "1px 1px 10px 0 #4040400D"}}/>
//                                 </RadioGroup>
//                             </div>
//                         </FormControl>
//                     </Grid>
//                     <Grid item xs={12} container alignItems="center">
//                         <Typography
//                             fontSize="16px"
//                             fontWeight="500"
//                             lineHeight="18.03px"
//                             component="label"
//                             htmlFor="age"
//                             width="13vw"
//                         >
//                             Age
//                         </Typography>
//                         <TextField
//                             disabled
//                             id="age"
//                             name="age"
//                             value={account.age}
//                             InputProps={{ endAdornment: 'Years'}}
//                             variant="outlined"
//                             sx={{ width: "27vw" }}
//                         />
//                     </Grid>
//                 </Grid>
//                 <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: "15px"}}>
//                     <Button
//                         variant='contained'
//                         sx={{
//                             background: `linear-gradient(270deg, #051572 7.32%, #0820B4 92.68%),
//                             linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
//                             backgroundBlendMode: 'normal, normal',
//                         }}
//                     >
//                         Update
//                     </Button>
//                 </div>
//                 <Accordion>
//                     <AccordionSummary
//                         expandIcon={<ExpandMoreIcon />}
//                         aria-controls="panel1a-content"
//                         id="panel1a-header"
//                     >
//                         <Typography>Login Credentials</Typography>
//                     </AccordionSummary>
//                     <Divider variant="middle" />
//                     <AccordionDetails>
//                         <Grid container rowSpacing={2} marginTop="1px">
//                             <Grid item xs={12} container alignItems="center">
//                                 <Typography
//                                     fontSize="16px"
//                                     fontWeight="500"
//                                     lineHeight="18.03px"
//                                     component="label"
//                                     htmlFor="userid"
//                                     width="13vw"
//                                 >
//                                     User ID
//                                 </Typography>
//                                 <TextField
//                                     disabled
//                                     id="userid"
//                                     name="userid"
//                                     value={account.id}
//                                     variant="outlined"
//                                     sx={{ width: "27vw" }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} container alignItems="center">
//                                 <Typography
//                                     fontSize="16px"
//                                     fontWeight="500"
//                                     lineHeight="18.03px"
//                                     component="label"
//                                     htmlFor="password"
//                                     width="13vw"
//                                 >
//                                     Current Password
//                                 </Typography>
//                                 <TextField
//                                     type='password'
//                                     disabled
//                                     id="password"
//                                     name="password"
//                                     value={account.password}
//                                     variant="outlined"
//                                     sx={{ width: "27vw" }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} container alignItems="center">
//                                 <Typography
//                                     fontSize="16px"
//                                     fontWeight="500"
//                                     lineHeight="18.03px"
//                                     component="label"
//                                     htmlFor="newpassword"
//                                     width="13vw"
//                                 >
//                                     New Password
//                                 </Typography>
//                                 <TextField
//                                     type='password'
//                                     placeholder='Enter New Password'
//                                     id="newpassword"
//                                     name="newpassword"
//                                     variant="outlined"
//                                     sx={{ width: "27vw" }}
//                                 />
//                             </Grid>
//                             <Grid item xs={12} container alignItems="center">
//                                 <Typography
//                                     fontSize="16px"
//                                     fontWeight="500"
//                                     lineHeight="18.03px"
//                                     component="label"
//                                     htmlFor="renewpassword"
//                                     width="13vw"
//                                 >
//                                     Re-Type New password
//                                 </Typography>
//                                 <TextField
//                                     type='password'
//                                     placeholder='Re-Enter New Password'
//                                     id="renewpassword"
//                                     name="renewpassword"
//                                     variant="outlined"
//                                     sx={{ width: "27vw" }}
//                                 />
//                             </Grid>
//                         </Grid>
//                         <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: "15px"}}>
//                             <Button className='editButton'>Edit</Button>
//                             <Button className='saveButton' variant='contained'>Save</Button>
//                         </div>
//                     </AccordionDetails>
//                 </Accordion>
//             </Box> */}
//         </div>
//     </div>
//     </>
//   )
// }

// export default Profile





import React from 'react';
import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import userProfile from "../../assests/images/Doctor.jpg";
import "./styles.css";
import Header from '../header/header';

const Profile = ({ userData }) => {
    const handleLogOut = () => {
        localStorage.clear();
        window.location.href = "/";
    }

    return (
        <>
            {/* Render Header component only for non-mobile views */}
            <div className="headerContainer">
                <Header />
            </div>

            <div className='userAccount'>
                <Stack direction="row" padding="10px 5%" justifyContent="space-between" alignItems="center">
                    <Typography fontSize="28px" fontWeight="500" lineHeight="31px">
                        Account
                    </Typography>
                </Stack>
                <div className='accountDetails'>
                    <Box
                        display="flex"
                        flexDirection={{ xs: 'column', md: 'row' }}
                        justifyContent="center"
                        alignItems="center"
                        gap={4}
                    >
                        <Avatar alt="User Account" src={userProfile} sx={{ width: { xs: '60vw', md: '33%' }, height: { xs: '30vh', md: '40%' }, borderRadius: '50%' }} />
                        <div style={{ padding: '2%', width: { xs: '80vw', md: '35%' }, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', gap: '10px' }}>
                            <Typography fontSize={{ xs: '24px', md: '32px' }} fontWeight="500" lineHeight="36px" color="#0D1E83" textTransform="capitalize">{userData.name}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                                <div>
                                    <Typography fontSize={{ xs: '18px', md: '26px' }} fontWeight="320" lineHeight="28.2px" textTransform="capitalize">{userData.age}Y / {userData.sex}</Typography>
                                </div>
                                <div>
                                    <Typography fontSize={{ xs: '18px', md: '26px' }} fontWeight="320" lineHeight="28.2px">ID: {userData.user.username}</Typography>
                                </div>
                            </div>
                            <Typography fontSize={{ xs: '18px', md: '26px' }} fontWeight="320" lineHeight="28.2px" width="max-content">{userData.metadata.hospital ? userData.metadata.hospital : "AIIMS"}</Typography>
                            <Divider sx={{ background: "rgba(13, 30, 131, 0.42)" }} />
                            <div className="logoutButtonContainer">
                                <div className="logoutButton" onClick={handleLogOut}>Log Out</div>
                            </div>
                        </div>
                    </Box>
                </div>
            </div>
        </>
    )
}

export default Profile