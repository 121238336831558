import React from "react";
import { Divider, IconButton, Typography, useMediaQuery, Box, useTheme } from "@mui/material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const PatientDetailsComponent = ({
    edit, handlePatientDetailsEditClick, pat_name, pat_age, pat_sex, visitNumber, visitDate, pat_id, pat_phone, docName,
}) => {
    // const isMobile = useMediaQuery('(max-width:600px)'); // Adjust the breakpoint as needed
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));


    return (
        <>
            <div style={{ display: "inline-flex", padding: "0 5%" }}>
                <Typography fontWeight={400} fontSize="28px" color="#0D1E83" textTransform="capitalize">{pat_name}</Typography>
                {edit ? <IconButton onClick={handlePatientDetailsEditClick}>
                    <EditOutlinedIcon fontSize="small" sx={{ color: "#0D1E83" }} />
                </IconButton> : null}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "0 5%" }}>
                {
                    !isMobile ? (
                        <div>
                        <Typography textTransform="capitalize" fontWeight={400} fontSize="16px" color="#0D1E83">
                            {`${pat_age} years | ${pat_sex} `} {visitNumber === null || visitNumber === undefined ? "" : `| Visit No. ${visitNumber + 1}`}
                        </Typography>
                        <Typography display="flex" fontSize="16px" fontWeight={600} color="#0D1E83">
                            Visit Date: {visitDate}
                        </Typography>
                        </div>
                    ) : (
                        <Typography textTransform="capitalize" fontWeight={400} fontSize="16px" color="#0D1E83">
                        {`${pat_age} years | ${pat_sex} `} {visitNumber === null || visitNumber === undefined ? "" : `| Visit No. ${visitNumber + 1} `}
                        {`| Visit Date: ${visitDate}`}
                        </Typography>
                    )
                }
            </div>
            <Divider sx={{ background: "rgba(13, 30, 131, 0.22)", margin: "1% 5%" }} />
            {isMobile ? (
                <Box display="flex" flexDirection="column" padding="0.5% 5%">
                    <Typography display="flex" fontWeight="bold" fontSize="16px" color="#404040" gap={1}>
                        Patient ID:
                        <Typography fontWeight={400}>{pat_id}</Typography>
                    </Typography>
                    <Typography display="flex" fontWeight="bold" fontSize="16px" color="#404040" gap={1}>
                        Phone Number:
                        <Typography fontWeight={400}>+91 {pat_phone}</Typography>
                    </Typography>
                    <Typography display="flex" fontWeight="bold" fontSize="16px" color="#404040" gap={1}>
                        Referred by:
                        <Typography fontWeight={400} textTransform="capitalize">{docName}</Typography>
                    </Typography>
                </Box>
            ) : (
                <div style={{ display: "flex", justifyContent: "space-between", padding: "0.5% 5%" }}>
                    <Typography display="flex" fontWeight="bold" fontSize="16px" color="#404040" gap={1}>
                        Patient ID:
                        <Typography fontWeight={400}>{pat_id}</Typography>
                    </Typography>
                    <Typography display="flex" fontWeight="bold" fontSize="16px" color="#404040" gap={1}>
                        Phone Number:
                        <Typography fontWeight={400}>+91 {pat_phone}</Typography>
                    </Typography>
                    <Typography display="flex" fontWeight="bold" fontSize="16px" color="#404040" gap={1}>
                        Referred by:
                        <Typography fontWeight={400} textTransform="capitalize">{docName}</Typography>
                    </Typography>
                </div>
            )}
        </>
    )
}

export default PatientDetailsComponent;