import React, { useState } from "react";
import { Box, Step, StepLabel, Stepper, Typography, useTheme, useMediaQuery } from '@mui/material';
import {makeStyles} from "@mui/styles";
import PatientDetails from "../patientDetails/patientDetails";
import HealthInformation from "../healthInformation/healthInformation";
import ImageUpload from "../imageUpload/imageUpload";
import axios from "axios";
import config from "../../config";


const useStyles = makeStyles((theme) => ({
    StepLabel: {
        fontWeight: "500",
        fontSize: "1.2rem !important",
        color: "rgba(148, 148, 148, 1)",
    },
}));

function getStepContent(
  formik,
  step,
  handleNext,
  handleBack,
  handleSubmit,
  handleAddPatient,
  handleGetUser,
  handleUpdateUser,
  findPatient,
  setFindPatient,
  idType,
  setIdType,
  idValue,
  setIdValue,
) {
  switch (step) {
    case 0:
      return (
        <PatientDetails
          handleNext={handleNext}
          formik={formik}
          handleAddPatient={handleAddPatient}
          handleGetUser={handleGetUser}
          handleUpdateUser={handleUpdateUser}
          findPatient={findPatient}
          setFindPatient={setFindPatient}
          idType={idType}
          setIdType={setIdType}
          idValue={idValue}
          setIdValue={setIdValue}
        />
      );
    case 1:
      return (
        <HealthInformation
          handleBack={handleBack}
          handleNext={handleNext}
          formik={formik}
        />
      );
    case 2:
      return (
        <ImageUpload
          handleBack={handleBack}
          handleSubmit={handleSubmit}
          formik={formik}
        />
      );
    default:
      throw new Error("Unknown step");
  }
};

function getHeadContent(step) {
    switch (step) {
        case 0:
            return "Patient Details";
        case 1:
            return "Health Information";
        case 2:
            return "Patient Image Upload";
        default:
             throw new Error('Unknown Step');
    }
}


const PatientForm = ({formik, steps, activeStep, setActiveStep, handleSubmit}) => {
    const classes = useStyles();
    const header = {"Authorization": "Token "+localStorage.getItem("optha-token")};
    const [findPatient, setFindPatient] = useState(false);
    const [idType, setIdType] = useState("other_id");
    const [idValue, setIdValue] = useState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleAddPatient = async () => {
        try {
            const body = {
                user: {
                    first_name: formik.values.pat_info.name.split(' ')[0],
                    last_name: formik.values.pat_info.name.split(' ').slice(1).join('_'),
                },
                name: formik.values.pat_info.name,
                age: formik.values.pat_info.age,
                sex: formik.values.pat_info.gender,
                role: "patient",
                phone: formik.values.pat_info.number,
                // [formik.values.pat_info.uniqueId.idType]: formik.values.pat_info.uniqueId.idValue,
                other_id: formik.values.pat_info.otherId,
                // abha_id: formik.values.pat_info.abhaId,
                abha_id: formik.values.pat_info.abhaId !== "" ? formik.values.pat_info.abhaId : undefined
            };
            const response = await axios
            .post(`${config.API_BASE_URL}/api/auth/users/`, body, {headers: header});
            if(response.data.id) {
                localStorage.setItem('patient_id', response.data.id);
                localStorage.setItem('doc_id', formik.values.pat_info.referredBy.docId);
                handleNext();
            }
        }
        catch (error) {
            alert(error.response.data?.[formik.values.pat_info.otherId]);
        }
    };

    const handleGetUser = async () => {
        let patient_id;
        await axios
        .get(`${config.API_BASE_URL}/api/auth/users/?${idType}=${idValue}`, {headers: header})
        .then((response) => {
            if(response?.data[0]?.id !== undefined) {
                patient_id = response.data[0].id;
                localStorage.setItem('patient_id', response.data[0].id);
                formik.setFieldValue("pat_info.otherId", response.data[0].other_id);
                if(response.data[0].abha_id) {
                    formik.setFieldValue("pat_info.abhaId", response.data[0].abha_id);
                }
                setFindPatient(true);
            }
            else {
                alert("Id not registered!!!");
            }
        })
        .catch((error) => {console.log(error); setFindPatient(false)});
        if(patient_id) {
            await axios
            .get(`${config.API_BASE_URL}/api/reports/getreport/?patient_id=${patient_id}`, {headers: header})
            .then((response) => {
                formik.setFieldValue("pat_info.referredBy.docId", response.data.results[0].doctor.id);
                formik.setFieldValue("pat_info.referredBy.docName", response.data.results[0].doctor.name);
                localStorage.setItem('doc_id', response.data.results[0].doctor.id);
            })
            .catch((error) => {
                alert("Unable to get Doctor for this Patient!!!");
                console.log(error);
            });
        }
    };

    const handleUpdateUser = async ({patient_id}) => {
        const body = {
            user: {
                first_name: formik.values.pat_info.name.split(' ')[0],
                last_name: formik.values.pat_info.name.split(' ').slice(1).join('_'),
            },
            name: formik.values.pat_info.name,
            age: formik.values.pat_info.age,
            sex: formik.values.pat_info.gender,
            role: "patient",
            phone: formik.values.pat_info.number,
            [formik.values.pat_info.uniqueId.idType]: formik.values.pat_info.uniqueId.idValue,
        };
        await axios
        .put(`${config.API_BASE_URL}/api/auth/users/${patient_id}/`, body, {headers: header})
        .then((response) => {
            alert(`User Profile Updated!!!`);
            localStorage.setItem('doc_id', formik.values.pat_info.referredBy.docId);
            handleNext();
        })
        .catch((error) => {
            alert("Not able to update the Profile!!!");
            console.log(error);
        })
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1);
        console.log(formik.values);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

  return (
    <div>
        <Box sx ={{marginX: isMobile ? "0vw" : "20vw"}} >
            {!isMobile && <Stepper activeStep={activeStep}>
            {steps.map((label) =>
                (
                <Step key={label}>
                    <StepLabel classes={{label: classes.StepLabel}}>{label}</StepLabel>
                </Step>
                ))
            }
            </Stepper>}
            <Box
                padding={isMobile?"16px":"24px"}
                border="1px solid rgba(0, 0, 0, 0.05)"
                borderRadius="8px"
                marginTop="15px"
            >
                <Typography fontWeight="500" fontSize="24px" marginBottom="16px">{getHeadContent(activeStep)}</Typography>
                {getStepContent(formik, activeStep, handleNext, handleBack, handleSubmit, handleAddPatient, handleGetUser, handleUpdateUser, findPatient, setFindPatient, idType, setIdType, idValue, setIdValue)}
            </Box>
        </Box>
    </div>
  )
}

export default PatientForm;