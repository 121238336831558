import React from "react";
import { Box, Typography } from '@mui/material';
import inferenceImage from "../../assests/images/InferenceImage.svg";

const ProcessingScreen = () => {
  return (
    <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box
        sx={{
          background: "rgba(249, 252, 255, 1)",
          maxWidth: { xs: "90%", md: "40vw" },
          height: { xs: "auto", md: "12.5vh" },
          padding: { xs: "16px", md: "24px 36px" },
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "center",
          textAlign: { xs: "center", md: "unset" },
          mx: { xs: "auto", md: 0 },
        }}
      >
        <Box sx={{ marginBottom: { xs: "16px", md: 0 } }}>
          <img src={inferenceImage} alt="Inference Animation" />
        </Box>
        <Box>
          <Typography fontWeight="500" fontSize={{ xs: "16px", md: "20px" }} sx={{ color: "#0D1E83" }}>
            AI Inference is being Generated
          </Typography>
          <Typography fontWeight="400" fontSize={{ xs: "14px", md: "16px" }} sx={{ color: "#0D1E83" }}>
            Sit tight, as we process your request, this might take a few minutes
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default ProcessingScreen;