// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drawerContent {
  width: 81.338vw;
}

.drawerContent .MuiBox-root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
}

.drawerContent .MuiTypography-root {
  padding-left: 16px;
}

.drawerContent .MuiIconButton-root {
  padding-right: 16px;
}


@media (min-width: 900px) {
  .menuBar {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/menuComponent/styles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;;AAGA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".drawerContent {\n  width: 81.338vw;\n}\n\n.drawerContent .MuiBox-root {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 16px;\n}\n\n.drawerContent .MuiTypography-root {\n  padding-left: 16px;\n}\n\n.drawerContent .MuiIconButton-root {\n  padding-right: 16px;\n}\n\n\n@media (min-width: 900px) {\n  .menuBar {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
