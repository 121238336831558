import React from "react";
import "./styles.css";
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    floatingButton: {
        position: 'fixed',
        marginRight: "1.75%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "1vw",
        padding: "0.5% 1.25%",
        height: "7.25vh",
        bottom: "5%",
        left: "82.5%",
        background: "var(--gradient, linear-gradient(270deg, #051572 0%, #0820B4 100%))",
        color: "#FFFFFF",
        borderRadius: "8px",
        cursor: "pointer",
        "&:hover": {
            border: "1px solid rgba(255, 255, 255, 0.12)",
            background: "var(--Gradient, linear-gradient(270deg, #051572 7.32%, #0820B4 92.68%))",
            boxShadow: "0px 0px 4px 1px rgba(8, 32, 180, 0.09), 0px 1px 6px 2px rgba(13, 30, 131, 0.31)",
        },
        "@media (max-width: 1200px)": {
            padding: "0.5% 1%",
            height: "6.5vh",
        },
        "@media (max-width: 900px)": {
            padding: "0.5% 0.75%",
            height: "6vh",
        },
        "@media (max-width: 600px)": {
            padding: "0.5% 0.5%",
            height: "5.5vh",
            left: "75%",
        },
        "@media (max-width: 500px)": {
            padding: "0.5% 0.5%",
            height: "5.5vh",
            left: "70%",
            gap: "1vw",
            fontSize: "0.75rem",
        },
        "@media (max-width: 400px)": {
            padding: "0.5% 0.25%",
            height: "5vh",
            left: "65%",
            gap: "2vw",
            fontSize: "0.75rem",
        },
    },
    iconContainer: {
        borderRadius: "50%",
        background: "#2338AE",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "@media (max-width: 400px)": {
            width: "1.5rem",
            height: "1.5rem",
        },
    },
    textContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

const FloatingButton = ({ onClick }) => {
    const classes = useStyles();
    return (
        <div className={classes.floatingButton} onClick={onClick}>
            <div className={classes.iconContainer}>
                <AddIcon />
            </div>
            <div className={classes.textContainer}>
                <span>Add Patient Record</span>
            </div>
        </div>
    );
};

export default FloatingButton;
