import React from "react";
import { Backdrop, Box, CircularProgress, Typography, useMediaQuery, useTheme } from '@mui/material';

const LoadingComponent = ({ open, text }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => theme.zIndex.drawer + 2,
      }}
      open={open}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          background: "white",
          width: isMobile ? "80%" : "25%",
          height: isMobile ? "30%" : "25%",
          alignItems: "center",
          justifyContent: "space-evenly",
          borderRadius: "16px",
        }}
      >
        {text ? (
          <Typography
            color="#2D363D"
            fontSize={isMobile ? "16px" : "20px"}
            fontWeight={600}
          >
            Submitting Report
          </Typography>
        ) : null}
        <CircularProgress sx={{ color: "#0D1E83" }} />
      </Box>
    </Backdrop>
  );
};

export default LoadingComponent;
