import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  customButton: {
    '&:hover': {
      backgroundColor: '#9F0101 !important', // Hover state background color
    },
  },
}));
const ImageConfirm = ({open, eye, heading, file, handleConfirmationClose, handleConfirmationYes}) => {
    const classes = useStyles();

  return (
    <Dialog open={open} maxWidth="xs" height="md" borderRadius="16px">
        <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontWeight={500} fontSize="28px" color="#0D1E83">
                {heading}
            </Typography>
            <Button endIcon={<CloseIcon />} onClick={handleConfirmationClose} sx={{color: "#0D1E83"}}>Close</Button>
        </DialogTitle>
        <DialogContent>
            {`Are you sure “${file[0].file.name}” is the patients ${eye} eye image?`}
        </DialogContent>
        <DialogActions>
                <Box display="flex" justifyContent="space-evenly" alignItems="center" width="100%">
                <Button
                    className={classes.customButton}
                    sx={{
                        background: "#9F0101",
                        border: "1px solid var(--default-grey, #B8B3B4)",
                        borderRadius: "8px",
                        color: "var(--white, #FFF)",
                    }}
                    onClick={handleConfirmationClose}
                >
                    No
                </Button>
                <Button
                    sx={{
                        background: "var(--gradient, linear-gradient(270deg, #051572 0%, #0820B4 100%))",
                        border: "1px solid var(--blue, #0D1E83)",
                        borderRadius: "8px",
                        color: "var(--white, #FFF)",
                    }}
                    onClick={handleConfirmationYes}
                >
                    Yes
                </Button>
                </Box>
            </DialogActions>
    </Dialog>
  )
}

export default ImageConfirm