import React from "react";
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import errorIcon from "../../assests/images/errorIcon.svg";
import problemImage1 from "../../assests/images/problemImage1.svg";
import problemImage2 from "../../assests/images/problemImage2.svg";
import problemImage3 from "../../assests/images/problemImage3.svg";
import problemImage4 from "../../assests/images/problemImage4.svg";

const DisplayProblem = ({ problemImage, problemContent }) => (
    <Box display="flex" flexDirection="column" height="100%" alignItems="center">
        <div style={{ display: "flex", justifyContent: "center", height: "75%", width: "90%" }}>
            <img src={problemImage} alt={problemContent} style={{ height: "100%", width: "100%" }} />
        </div>
        <Typography width="95%" align="center" fontSize="12px" fontWeight={500}>{problemContent}</Typography>
    </Box>
);

const ProblemIssues = ({ open, handleProblemClose, eye }) => {
    const ProblemArray = [
        { "problemImage": problemImage1, "problemContent": "Artifacts affect more than 1/2 macular area" },
        { "problemImage": problemImage2, "problemContent": "Hazy image (due to cataract or significant corneal opacity)" },
        { "problemImage": problemImage3, "problemContent": "Image not centered (macular area not seen)" },
        { "problemImage": problemImage4, "problemContent": "Defocused Image" },
    ];

    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

    const chunkArray = (array, size) => {
        return array.reduce((chunks, item, index) => {
            if (index % size === 0) {
                chunks.push([item]);
            } else {
                chunks[chunks.length - 1].push(item);
            }
            return chunks;
        }, []);
    };

    const chunkedProblemArray = isMobileView ? chunkArray(ProblemArray, 2) : [ProblemArray];

    return (
        <div>
            <Dialog open={open} fullWidth={true} maxWidth="md">
                <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
                    <Typography fontWeight={500} fontSize={isMobileView?"20px":"28px"} color="#0D1E83">
                        Possible Issues
                    </Typography>
                    <Button endIcon={<CloseIcon />} onClick={handleProblemClose} sx={{ color: "#0D1E83" }}>Close</Button>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" gap="6px">
                            <div style={{ width: "6vw", height: "8vh", display: "flex", justifyContent: "center", alignContent: "center" }}>
                                <img src={errorIcon} alt="Error icon" />
                            </div>
                            <Typography width={isMobileView ? '100%' : '38vw'} color="#9F0101" fontSize={isMobileView?"15px":"20px"} fontWeight={400}>
                                Oops! It seems there are some potential issues with the rendered images of the patients - {eye}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection={isMobileView ? "column" : "row"}
                            justifyContent="space-evenly"
                            alignContent="center"
                            sx={{ height: isMobileView ? "auto" : "50%", width: "100%", gap: "2px" }}
                        >
                            {/* Mapping over chunkedProblemArray instead of ProblemArray */}
                            {chunkedProblemArray.map((chunk, index) => (
                                <Box key={index} display="flex" flexDirection="row" justifyContent="space-evenly" width="100%">
                                    {chunk.map((problem, subIndex) => (
                                        <Box
                                            key={subIndex}
                                            width={isMobileView ? "100%" : "auto"}
                                            mb={isMobileView ? 2 : 0} // Add margin bottom for mobile view
                                        >
                                            <DisplayProblem problemImage={problem.problemImage} problemContent={problem.problemContent} />
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ProblemIssues;
