import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ExitForm = ({open, handleExitFormClose, handleExitFormExit}) => {
    return (
        <Dialog open={open} maxWidth="xs" height="md" borderRadius="16px">
            <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
                <Typography color="#2D363D">Exit Patient Form</Typography>
                <Button endIcon={<CloseIcon />} onClick={handleExitFormClose} sx={{color: "#0D1E83"}}>Close</Button>
            </DialogTitle>
            <DialogContent>
                Are you sure you want to exit patient form? Once existed, the patient details will not be saved.
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="space-evenly" alignItems="center" width="100%">
                <Button
                    sx={{
                        color: "#9F0101",
                        border: "2px solid var(--text-red, #9F0101)",
                        borderRadius: "8px"
                    }}
                    onClick={handleExitFormExit}
                >
                    Exit Form
                </Button>
                <Button
                    sx={{
                        background: "var(--gradient, linear-gradient(270deg, #051572 0%, #0820B4 100%))",
                        border: "1px solid var(--blue, #0D1E83)",
                        borderRadius: "8px",
                        color: "var(--white, #FFF)"
                    }}
                    onClick={handleExitFormClose}
                >
                    Continue Form
                </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default ExitForm