import React, { useEffect, useState } from "react";
import PatientScreening from "../patientScreening/patientScreening";
import PatientInference from "../patientInference/patientInference";
import ProcessingScreen from "../processingScreen/processingScreen";
import { useNavigate } from "react-router-dom";
import ExitForm from "../patientScreening/exitForm";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from 'yup';
import config from "../../config";
import { RequiredRefferal, handleSetReferral, nextSteps } from "../../utlis";

const pat_info = {
    patientType: "new_patient",
    name: "",
    gender: "",
    age: "",
    // uniqueId: {
    //     idType: "",
    //     idValue: "",
    // },
    otherId: "",
    abhaId: "",
    number: "",
    referredBy: {
        docId: "",
        docName: "",
    },
};

const health_info = {
    preDiagnosedDiabetes: "",
    durationDiabetesDetection: "",
    diabetesType: "",
    HbA1CLevel: "",
    systolicBloodPressure: "",
    diastolicBloodPressure: "",
    otherComplications: [],
    visualAcuity: {
        left: "",
        right: "",
    },
    familyHistory: [],
    notes: ""
};

const image_file = {
    left: {
        gradabale: "",
        image: {},
        reason: "",
        classification: {},
        gradability: {},
        ishazy: false,
    },
    right: {
        gradabale: "",
        image: {},
        reason: "",
        classification: {},
        gradability: {},
        ishazy: false,
    },
};

const validationSchema = Yup.object().shape({
    pat_info: Yup.object().shape({
        name: Yup.string()
            .matches(/^[A-Za-z\s]+$/, 'Name should contain only alphabetical characters')
            .required('Name is required'),
        gender: Yup.string().required('Gender is required'),
        age: Yup.number()
            .min(0, 'Age cannot be negative')
            .max(100, 'Max age is 100')
            .required('Age is required'),
        // uniqueId: Yup.object().shape({
        //     idType: Yup.string().required('ID Type is required'),
        //     idValue: Yup.string()
        //           .matches(/^[A-Za-z0-9]+$/, 'ID Value should be alphanumeric')
        //           .required('ID Value is required'),
        // }),
        otherId: Yup.string()
            .matches(/^[A-Za-z0-9]+$/, 'ID Value should be alphanumeric')
            .required('ID Value is required'),
        abhaId: Yup.string()
            .matches(/^[A-Za-z0-9]+$/, 'ID Value should be alphanumeric'),
        number: Yup.string()
            .matches(/^[0-9]{10}$/, 'Number should contain 10 numerical characters')
            .required('Number is required'),
    }),
    health_info: Yup.object().shape({
        preDiagnosedDiabetes: Yup.string().required("Pre-diagnosed Diabetes is required"),
        durationDiabetesDetection: Yup.number().test('Conditional Required', "Required", function(value) {
            const firstFieldValue = this.parent.preDiagnosedDiabetes; // Access the value of firstField
            return firstFieldValue === 'yes' ? !!value : true;
            })
            .min(0, 'Year Cannot be less than 0'),
        diabetesType: Yup.string().test('Conditional Required', "Required", function(value) {
            const firstFieldValue = this.parent.preDiagnosedDiabetes; // Access the value of firstField
            return firstFieldValue === 'yes' ? !!value : true;
        }),
        HbA1CLevel: Yup.number()
            .min(0, 'Cannot be negative'),
        systolicBloodPressurebloodPressure: Yup.number()
            .min(0, "Cannot be negative"),
        diastolicBloodPressure: Yup.number()
            .min(0, "Cannot be negative"),
        visualAcuity: Yup.object().shape({
            left: Yup.string().required("Both left and right are required!!!")
            .matches(/^(?:[0-9]\/[0-9]{1,2}|PL-|PL\+|HMCF|FCCF)?$/, 'Visual Acuity should be in the format "X/YY" or PL- or PL+ or HMCF or FCCF'),
            right: Yup.string().required("Both left and right are required!!!")
            .matches(/^(?:[0-9]\/[0-9]{1,2}|PL-|PL\+|HMCF|FCCF)?$/, 'Visual Acuity should be in the format "X/YY" or PL- or PL+ or HMCF or FCCF'),
        }),
    }),
  });

const PatientScreeningWrapper = ({userData}) => {
    const navigate = useNavigate();
    const header = {"Authorization": "Token "+localStorage.getItem("optha-token")};
    const formik = useFormik({ initialValues: {
        pat_info: pat_info, health_info: health_info, image_file: image_file
        }, validationSchema: validationSchema,
    });
    const patient = localStorage.getItem('patient_id');
    const idexists = !!patient;
    const [inferenceState, setInferenceState] = useState({
        inference: {
            "left": "",
            "right": "",
            "req": "",
        },
        inferenceProvided: false,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [onExit, setOnExit] = useState(false);
    const [consent, setConsent] = useState(idexists);
    const [step, setStep] = useState(idexists ? 1 : 0);
    const [submittingReport, setSubmittingReport] = useState(false);

    useEffect(() => {
        if(idexists && inferenceProvided === false) {
            axios
            .get(`${config.API_BASE_URL}/api/auth/users/?id=${patient}`, {headers: header})
            .then((res) => {
                formik.setFieldValue("pat_info.name", res.data[0].name);
                formik.setFieldValue("pat_info.age", res.data[0].age);
                formik.setFieldValue("pat_info.gender", res.data[0].sex);
                if(res.data[0].abha_id !== null) {
                    formik.setFieldValue("pat_info.uniqueId.idType", "abha_id");
                    formik.setFieldValue("pat_info.uniqueId.idValue", res.data[0].abha_id);
                }
                else if(res.data[0].other_id !== null) {
                    formik.setFieldValue("pat_info.uniqueId.idType", "other_id");
                    formik.setFieldValue("pat_info.uniqueId.idValue", res.data[0].other_id);
                }
                formik.setFieldValue("pat_info.number", res.data[0].phone);
            })
            .catch((error) => console.log(error));
        }
    }, [idexists, inferenceState]);

    function isEmptyObject(obj) {
        return Object.keys(obj).length === 0;
    }

    const handleModelResponse = (data, inference) => {
        const model_response = {
            classification: {},
            gradability: {},
            ishazy: {},
            reason: {},
            isReferable: {},
            nextSteps: {},
        };

        for (const key in data) {
            const classification = data[key].classification;
            const gradability = data[key].gradability;
            const ishazy = data[key].ishazy;
            const reason = data[key].reason;

            if (!isEmptyObject(classification)) {
                model_response.classification[key] = classification;
            }

            if (!isEmptyObject(gradability)) {
                model_response.gradability[key] = gradability;
            }
            model_response.ishazy[key] = ishazy;
            model_response.reason[key] = reason;
            model_response.isReferable[key] = RequiredRefferal(data[key].classification.predicted_class_name);
        }
        // console.log(model_response);
        model_response.nextSteps = handleNextSteps(inference);
        console.log(model_response);
        return model_response;
    };

    // const handleCreateReason = (data) => {
    //     const reason = {};
    //     for (const key in data) {
    //         const r = data[key].reason;
    //         if(r !== "") {
    //             reason[key] = r;
    //         }
    //     }
    //     return reason;
    // };

    // const handleIsReferable = (data) => {
    //     const isReferable = {};
    //     for (const key in data) {
    //         if(key !== "req")
    //             isReferable[key] = RequiredRefferal(data[key]);
    //     };
    //     return isReferable;
    // };

    const handleNextSteps = (data) => {
        const {message, date} = nextSteps(data?.left, data?.right);
        const nextStep = `${message} on ${date}, and suggest them to monitor their sugar level.`
        return nextStep;
    }

    // const setRequired = ({left,right}) => {
    //     if((left === "No Diabetic Retinopathy" || left === "Mild Diabetic Retinopathy") && (right === "No Diabetic Retinopathy" || right === "Mild Diabetic Retinopathy")) {
    //         return "Not Required";
    //     }
    //     else {
    //         return "Required";
    //     }
    // };

    const handleExit = () => {
        setOnExit(true);
    };
    const handleExitFormClose = () => {
        setOnExit(false);
    };
    const handleExitFormExit = () => {
        navigate("/dashboard");
        localStorage.removeItem("patient_id");
        localStorage.removeItem("doc_id");
    };

    const handlePatientDetailsEditClick = () => {
        setInferenceState((prevState) => ({
            ...prevState,
            inference: {
              left: "",
              right: "",
              req: ""
            },
            inferenceProvided: false
        }));
        setConsent(true);
        setStep(0);
    };

    const handleHealthEditClick = () => {
        setInferenceState((prevState) => ({
            ...prevState,
            inference: {
              left: "",
              right: "",
              req: ""
            },
            inferenceProvided: false
        }));
        setConsent(true);
        setStep(1);
    };

    const handleImageEditClick = () => {
        setInferenceState((prevState) => ({
            ...prevState,
            inference: {
              left: "",
              right: "",
              req: ""
            },
            inferenceProvided: false
        }));
        setConsent(true);
        setStep(2);
    }
    const handleSubmit = async () => {
        // Perform API call here
        // console.log(formik.values);
    setIsLoading(true);
        const formDataleft = new FormData();
        const formDataright = new FormData();
        formDataright.append('model_name', 'classification_model');
        formDataright.append('image', formik.values.image_file.right.image);
        let res1, res2;
        if(!(formik.values.image_file.right.gradable === "Non Gradable" && formik.values.image_file.right.reason === "Hazy image due to Cataract")) {
            res1 = await axios
            .post(`${config.API_BASE_URL}/api/reports/model_inference/`, formDataright, {headers: header})
            .catch((error) => {
                console.log(error);
                if(error.response.status === 500)
                    alert("Model not working, Refresh and Try again!!!");
                else
                    alert("Error in processing image, Try again!!!");
            });
        }
        formDataleft.append('model_name', 'classification_model');
        formDataleft.append('image', formik.values.image_file.left.image);
        if(!(formik.values.image_file.left.gradable === "Non Gradable" && formik.values.image_file.left.reason === "Hazy image due to Cataract")) {
            res2 = await axios
            .post(`${config.API_BASE_URL}/api/reports/model_inference/`, formDataleft, {headers: header})
            .catch((error) => {
                console.log(error);
                if(error.response.status === 500)
                    alert("Model not working, Refresh and Try again!!!");
                else
                    alert("Error in processing image, Try again!!!");
            });
        }
        await Promise.all([res1, res2])
        .then((responses) => {
            // Handle the responses from both requests
            const response1 = responses[0];
            const response2 = responses[1];
            if(response1 && response2) {
                formik.setFieldValue("image_file.left.classification", response2.data);
                formik.setFieldValue("image_file.right.classification", response1.data);
                setInferenceState((prevState) => ({
                    ...prevState,
                    inference: {
                        left: response2.data.predicted_class_name,
                        right: response1.data.predicted_class_name,
                        req: handleSetReferral(response2.data.predicted_class_name, response1.data.predicted_class_name),
                    },
                    inferenceProvided: true,
                }));
            }
            else if(response1 === undefined && response2 === undefined) {
                setInferenceState((prevState) => ({
                    ...prevState,
                    inference: {
                        left: "Hazy image (due to cataract or significant corneal opacity)",
                        right: "Hazy image (due to cataract or significant corneal opacity)",
                        req: handleSetReferral(inference.left, inference.right),
                    },
                    inferenceProvided: true,
                }));
            }
            else if (response1 === undefined && response2) {
                formik.setFieldValue("image_file.left.classification", response2.data);
                setInferenceState((prevState) => ({
                    ...prevState,
                    inference: {
                        left: response2.data.predicted_class_name,
                        right: "Hazy image (due to cataract or significant corneal opacity)",
                        req: handleSetReferral(inference.left, inference.right),
                    },
                    inferenceProvided: true,
                }));
            }
            else if(response2 === undefined && response1) {
                formik.setFieldValue("image_file.right.classification", response1.data);
                setInferenceState((prevState) => ({
                    ...prevState,
                    inference: {
                        left: "Hazy image (due to cataract or significant corneal opacity)",
                        right: response1.data.predicted_class_name,
                        req: handleSetReferral(inference.left, inference.right),
                    },
                    inferenceProvided: true,
                }));
            }
        })
        .catch((error) => {
            // Handle errors if any of the requests fail
            console.log('An error occurred:', error);
        });
    setIsLoading(false);
    console.log(inference);
    };

    const handleSubmitReport = async () => {
        const ml_model = handleModelResponse(formik.values.image_file, inference);
        // const reason = handleCreateReason(formik.values.image_file);
        // const isReferable = handleIsReferable(inference);
        // const nextSteps = handleNextSteps(inference);
        const formData = new FormData();
        formData.append("ophthalmologist_id", userData.id);
        formData.append("health_info", JSON.stringify(formik.values.health_info));
        formData.append("patient_id", localStorage.getItem("patient_id"));
        formData.append("left", formik.values.image_file.left.image);
        formData.append("right", formik.values.image_file.right.image);
        formData.append("doctor_id", localStorage.getItem("doc_id"));
        formData.append("ml_model", JSON.stringify(ml_model));
        // formData.append("reason", JSON.stringify(reason));
        // formData.append("isReferable", JSON.stringify(isReferable));
        // formData.append("nextSteps", JSON.stringify(nextSteps));
        setSubmittingReport(true);
        await axios
        .post(`${config.API_BASE_URL}/api/reports/addreport/`, formData, {headers: header})
        .then((response) => {
            localStorage.setItem("report_id", response.data.uuid);
            localStorage.setItem("doc_name", formik.values.pat_info.referredBy.docName);
            navigate("/dashboard");
            localStorage.removeItem("patient_id");
            localStorage.removeItem("doc_id");
        })
        .catch((error) => {
            console.log(error);
            alert("Error Submitting Report!!");
        });
        setSubmittingReport(false);
    }

    const {inference, inferenceProvided} = inferenceState;

  return (
    <>
        {inferenceProvided ?
            <PatientInference
                formik={formik}
                inference={inference}
                handleExit={handleExit}
                handlePatientDetailsEditClick={handlePatientDetailsEditClick}
                handleImageEditClick={handleImageEditClick}
                handleHealthEditClick={handleHealthEditClick}
                handleSubmitReport={handleSubmitReport}
                submittingReport={submittingReport}
            />
            :
            (isLoading ?
                <ProcessingScreen />
                :
                <PatientScreening
                    formik={formik}
                    handleSubmit={handleSubmit}
                    handleExitFormExit={handleExitFormExit}
                    handleExit={handleExit}
                    consent={consent}
                    step={step}
                />
            )
        }
        <div>
            {onExit && <ExitForm open={onExit} handleExitFormClose={handleExitFormClose} handleExitFormExit={handleExitFormExit} />}
        </div>
    </>
  );
};

export default PatientScreeningWrapper