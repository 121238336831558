import React, { useEffect, useState } from "react";
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NewPatientForm from "./newPatientForm";
import FollowUpForm from "./followUpForm";


const PatientDetails = ({handleNext, formik, handleAddPatient, handleGetUser, handleUpdateUser, findPatient, setFindPatient, idType, setIdType, idValue, setIdValue}) => {
    const [selectedValue, setSelectedValue] = useState(formik.values.pat_info.patientType);
    const [disabledButton, setDisabledButton] = useState(true);

  const handleSelection = (event, newValue) => {
    setSelectedValue(newValue);
    formik.setFieldValue('pat_info.patientType', newValue);
  };

  const renderForm = () => {
    if (selectedValue === "new_patient") {
        return <NewPatientForm formik={formik}/>;
    } else if (selectedValue === "follow_up") {
        return <FollowUpForm formik={formik} handleFindPatient={handleFindPatient} findPatient={findPatient} setFindPatient={setFindPatient} idType={idType} setIdType={setIdType} idValue={idValue} setIdValue={setIdValue}/>;
    }
    return null;
  };
  useEffect(() => {handleProceedDisabled()}, [formik.values.pat_info, selectedValue, formik.errors?.pat_info]);
  const handleProceedDisabled = () => {
    if(selectedValue === "new_patient") {
        if(
            formik.values.pat_info.name &&
            formik.values.pat_info.age &&
            formik.values.pat_info.gender &&
            formik.values.pat_info.otherId &&
            formik.values.pat_info.number &&
            formik.values.pat_info.referredBy.docName !== "" &&
            !formik.errors?.pat_info
        ) {
            console.log("Enable Button");
            setDisabledButton(false);
        }
        else {
            // console.log(formik.errors);
            setDisabledButton(true);
        }
        // console.log(formik.values);
    }
    else if(selectedValue === "follow_up") {
        if( idValue &&
            // !formik.errors?.pat_info?.otherId &&
            formik.values.pat_info.referredBy.docName !== ""
        ) {
            setDisabledButton(false);
        }
        else {
            setDisabledButton(true);
        }
    }
  };

  const handleFindPatient = () => {
    handleGetUser();
  }
  return (
    <div>
        <ToggleButtonGroup
            value={selectedValue}
            exclusive
            onChange={handleSelection}
        >
            <ToggleButton value="new_patient"
                sx={{
                    border: "1px solid rgba(0, 0, 0, 0.15)",
                    fontSize: "16px",
                    fontWeight: "300",
                    color: "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                }}
                disabled={selectedValue === "new_patient"}
            >
                {selectedValue === "new_patient" ? <CheckIcon fontSize="small" sx={{paddingRight: "4px"}}/> : null} New Patient
            </ToggleButton>
            <ToggleButton value="follow_up"
                sx={{
                    border: "1px solid rgba(0, 0, 0, 0.15)",
                    fontSize: "16px",
                    fontWeight: "300",
                    color: "linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
                }}
                disabled={selectedValue === "follow_up"}
            >
                {selectedValue === "follow_up" ? <CheckIcon fontSize="small" sx={{paddingRight: "4px"}}/> : null} Follow Up
            </ToggleButton>
        </ToggleButtonGroup>
        <div style={{marginTop: "16px"}}>
            {renderForm()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: "16px", gap: "2.5%"}}>
            <Button disabled sx={{color: "#0D1E83"}}>Return</Button>
            <Button
                    variant='contained'
                    disabled={disabledButton}
                    onClick={() => {
                        if(localStorage.getItem("patient_id") && selectedValue === 'new_patient') {
                            let patient_id = localStorage.getItem("patient_id")
                            handleUpdateUser({patient_id});
                        }
                        else {
                            if(selectedValue === 'new_patient'){
                                handleAddPatient();
                            }
                            if(selectedValue === 'follow_up') {
                                localStorage.setItem('doc_id', formik.values.pat_info.referredBy.docId);
                                handleNext();
                            }
                        }
                    }}
                    borderRadius="8px"
                    fontWeight="400"
                    fontSize="16px"
                    style={{
                        padding: "12px 24px",
                        border: disabledButton ? "1px solid rgba(125, 121, 123, 0.50)" : "1px solid #7D797B80",
                        background: disabledButton ?
                        "var(--Grey, #F4EFF0)" :
                        'linear-gradient(270deg, #051572 7.32%, #0820B4 92.68%), linear-gradient(0deg, #FFFFFF, #FFFFFF)',
                    }}
                    endIcon={<ArrowForwardIcon />}
                >
                    Proceed
                </Button>
        </div>
    </div>
  )
}

export default PatientDetails