import React from "react";
import { Typography } from '@mui/material';

const DisclaimerComponent = () => {
  return (
    <div>
        <Typography fontSize="14px" fontWeight="400">
            This AI based Diabetic Retinopathy Screening application, has been developed under the guidance,
            supervision and training of qualified Doctors and several open-source images published by and legally available from global healthcare researchers.
            However, the interpretation made by AI is only a probability and we would leave it to the decision of the medical officer.
            NEN-AI disclaims any liability, claim, dispute etc. due to the results of this application.
            The information, including but not limited to, text, graphics, images and other material contained on this application are for informational purposes only.
            No material on this application is intended to be a substitute for professional medical advice, diagnosis or treatment.
            Always seek the advice of a qualified health care provider and never disregard professional medical advice or delay in seeking it because of something you have read or seen on this application.
        </Typography>
    </div>
  )
}

export default DisclaimerComponent