import React, { useEffect, useState } from "react";
import { Button, LinearProgress, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InformedConsent from "./informedConsent";
import PatientForm from "../patientForm/patientForm";
import { useNavigate } from "react-router-dom";
import ExitForm from "./exitForm";
import { steps } from "../../const.js";

const PatientScreening = ({formik, handleSubmit, handleExitFormExit, handleExit, consent, step}) => {
    const [consentGiven, setConsentGiven] = useState(consent);
    const [activeStep, setActiveStep] = useState(step);
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        setProgress((activeStep + 1) * (100 / (steps.length + 1)));
    }, [activeStep]);
    const handleProceed = () => {
        setConsentGiven(true);
    };
    useEffect(() => {
        // Disable scrolling when the popup is open
        if (!consentGiven) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Re-enable scrolling when the component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [consentGiven]);
  return (
    <div>
        {consentGiven && <LinearProgress variant="determinate" value={progress} sx={{backgroundColor: "transparent"}} />}
        <div>
        <Stack direction="row" padding="10px 5%" justifyContent="end" alignItems="center" >
            {/* <Typography fontSize="28px" fontWeight="500" lineHeight="31px">
                Patient Screening
            </Typography> */}
            <Button
                className='exitButton'
                sx={{borderRadius: "8px", color: "#0D1E83", border: "1px solid #0D1E83"}}
                endIcon={<CloseIcon />}
                onClick={() => {handleExit()}}
            >
                Exit
            </Button>
        </Stack>
        </div>
        <div>
            {!consentGiven ?
                (
                    <div style={{position: "relative"}}>
                        <PatientForm
                            formik={formik}
                            steps={steps}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                        />
                        <div>
                        <InformedConsent handleProceed={handleProceed} handleExit={handleExitFormExit}/>
                        </div>
                    </div>
                )
                :
                (
                        <PatientForm
                            formik={formik}
                            steps={steps}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            handleSubmit={handleSubmit}
                        />
                )
            }
        </div>
    </div>
  )
}

export default PatientScreening