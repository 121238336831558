import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ProceedNotGradable = ({formik, eye, open, question, options, handleProceedNotGradableClose, handleProceedNotGradableNext}) => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
        if(eye === "left") {
            formik.setFieldValue("image_file.left.reason", event.target.value);
            if(event.target.value === "Hazy image due to Cataract") {
                formik.setFieldValue("image_file.left.ishazy", true);
            }
        }
        else if(eye === "right") {
            formik.setFieldValue("image_file.right.reason", event.target.value);
            if(event.target.value === "Hazy image due to Cataract") {
                formik.setFieldValue("image_file.right.ishazy", true);
            }
        }
    };

  return (
    <Dialog open={open} width="md" height="md" borderRadius="16px">
        <DialogTitle display="flex" justifyContent="space-between" alignItems="center">
            <Typography color="#0D1E83">Proceeding with the Image</Typography>
            <Button
                endIcon={<CloseIcon />}
                onClick={handleProceedNotGradableClose}
                sx={{color: "#0D1E83"}}
            >
                Close
            </Button>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Please select your reason for proceeding with the ungradable image of the
                <Typography fontWeight="bold">
                    {question}
                </Typography>
            </DialogContentText>
            <RadioGroup value={selectedOption} onChange={handleOptionChange}>
                {options.map((option) => (
                    <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio />}
                    label={option.label}
                    />
                ))}
            </RadioGroup>
        </DialogContent>
        <DialogActions>
            <Button
                disabled={!selectedOption}
                endIcon={<ArrowForwardIcon />}
                sx={{
                    background: selectedOption ? "var(--gradient, linear-gradient(270deg, #051572 0%, #0820B4 100%))" : "var(--default-grey, #B8B3B4)",
                    borderRadius: "8px",
                    color: "var(--white, #FFF)",
                }}
                onClick={handleProceedNotGradableNext}
            >
                Next
            </Button>
        </DialogActions>
    </Dialog>
  );
};

export default ProceedNotGradable