import React, { useEffect, useState } from "react";
import { Button, Container, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, useTheme, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NewPatientForm from "./newPatientForm";


const FollowUpForm = ({formik, handleFindPatient = () => {}, findPatient, setFindPatient, idType, setIdType, idValue, setIdValue}) => {
    useEffect(() => {
        if(formik.values.pat_info.otherId === "" || formik.values.pat_info.abhaId === "") {
            setFindPatient(false);
        }
    }, [formik.values.pat_info.otherId, formik.values.pat_info.abhaId]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleIdChange = (e) => {
      setIdType(e.target.value);
    };
    const handleIdValueChange = (e) => {
      setIdValue(e.target.value);
    }
  return (
    <div>
      <Container sx={{ padding: "0px 24px 24px"}}>
        <Grid container spacing={2}>
          <Grid item container alignItems="center">
            <Grid item container alignItems="center">
              <Typography width="100%">Unique ID</Typography>
              <RadioGroup
                row
                id="idType"
                name="idType"
                onChange={handleIdChange}
                value={idType}
                sx={{ marginLeft: "10px"}}
              >
                <FormControlLabel
                  value="abha_id"
                  control={<Radio />}
                  label="ABHA ID"
                  sx={{
                    border: "1px solid rgba( 0, 0, 0, 0.05)",
                    borderRadius: "8px",
                    paddingRight: "10px",
                    boxShadow: "1px 1px 10px 0 #4040400D",
                  }}
                />
                <FormControlLabel
                  value="other_id"
                  control={<Radio />}
                  label="Other ID"
                  sx={{
                    border: "1px solid rgba( 0, 0, 0, 0.05)",
                    borderRadius: "8px",
                    paddingRight: "10px",
                    boxShadow: "1px 1px 10px 0 #4040400D",
                    marginLeft: isMobile?"":"16px",
                  }}
                />
              </RadioGroup>
            </Grid>
            {/* {formik.touched.pat_info?.uniqueId?.idType &&
            formik.errors.pat_info?.uniqueId?.idType ? (
              <Typography color="red">
                {formik.errors.pat_info.uniqueId.idType}
              </Typography>
            ) : null} */}
            <Grid
              item
              container
              gap="12px"
              sx={{ marginTop: "10px", marginLeft: isMobile?"":"13vw" }}
            >
              <TextField
                placeholder="Eg. 1234ABCD33DD"
                variant="outlined"
                id="idValue"
                name="idValue"
                onChange={handleIdValueChange}
                value={idValue}
                sx={{
                  border: "1px solid #0000000D",
                  borderRadius: "4px",
                  width: isMobile?"50%":"18vw",
                }}
                // error={
                //   formik.touched.pat_info?.otherId && formik.errors.pat_info?.otherId
                // }
              />
              {/* <Grid item container alignItems="center">
                <Typography width="13vw">Other ID*</Typography>
                <TextField
                  required
                  placeholder="Eg. 1234ABCD33DD"
                  variant="outlined"
                  id="pat_info.otherId"
                  name="pat_info.otherId"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.pat_info.otherId}
                  sx={{
                      border: "1px solid #0000000D",
                      borderRadius: "4px",
                      width: "25vw"
                  }}
                  error={formik.touched.pat_info?.otherId && formik.errors.pat_info?.otherId}
                /> */}
              <Button
                startIcon={<SearchIcon />}
                sx={{ color: "#0D1E83" }}
                onClick={() => {
                  localStorage.removeItem('patient_id');
                  localStorage.removeItem('doc_id');
                  formik.setFieldValue("pat_info.referredBy.docId", "");
                  formik.setFieldValue("pat_info.referredBy.docName", "");
                  handleFindPatient();
                }}
              >
                Find
              </Button>
              </Grid>
            {/* </Grid> */}
          </Grid>
          {formik.touched.pat_info?.otherId &&
          formik.errors.pat_info?.otherId ? (
            <Typography color="red" marginLeft="13vw" padding="0px 20px">
              {formik.errors.pat_info.otherId}
            </Typography>
          ) : null}
        </Grid>
      </Container>
        {findPatient && idValue && (
          <NewPatientForm formik={formik} findPatient={findPatient} />
        )}
    </div>
  );
}

export default FollowUpForm